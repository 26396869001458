import * as moment from 'moment';
import 'moment-timezone';

export class Aviso {
    idAviso: number;
    descripcion: string;
    comentario: string;
    fecha: moment.Moment;
    activo: number;
    idTipoAviso: number;

    public static parse(src: any): Aviso {
        const res = new Aviso();
        res.idAviso = src.hasOwnProperty('ID_Aviso') ? src.ID_Aviso : 1;
        res.descripcion = src.hasOwnProperty('Descripcion') ? src.Descripcion : '';
        res.comentario = src.hasOwnProperty('Comentario') ? src.Comentario : '';
        res.fecha = src.hasOwnProperty('Fecha') ? moment.tz(src.Fecha, 'Europe/Madrid').local() : null;
        res.activo = src.hasOwnProperty('Activo') ? src.Activo : 0;
        res.idTipoAviso = src.hasOwnProperty('ID_Tipo_Aviso') ? src.ID_Tipo_Aviso : 1;
        return res;
    }
}
