import { Injectable } from '@angular/core';
import {Par} from '../classes/matricula-solr';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoomFilterService {
  // public repositorios: Par[] = [];
  // public categorias: Par[] = [];
  // public generos: Par[] = [];
  public expired: Par[] = [];
  // public fechaIni: string;
  // public fechaFin: string;
  public fechaConsulta: string;
  public oldFechaConsulta: string;
  public curso: number;

  public filtersMsg: string;
  public noActivedFilters: boolean;
  public changeFilters = false;
  public initialized = false;
  public optionFilters = 'ninguno';

  public btnData: string;
  public sortParam: string;
  public processData: any = null;
//  public stateFilters: Subject<number>;
//  public stateFilters$: Observable<number>;


  constructor(private authService: AuthService) {
    this.filtersMsg = '';
    this.noActivedFilters = true;

   /* this.stateFilters = new Subject<number>();
    this.stateFilters$ = this.stateFilters.asObservable();*/
  }

  public initExpired() {
    this.expired = [];
    this.expired.push(new Par('1', 'Mostrar aulas caducadas', false));
  }

 /* public initRepositorios() {

    this.repositorios = [];
    this.repositorios.push(new Par('CC', 'Cadena Campus', true));
    this.repositorios.push(new Par('CU', 'Canal UNED', true));

   // this.old_repositorios = this.copyPares(this.repositorios);

  }*/
  // public initCategorias() {
  //
  //   this.categorias = [];
  //   this.categorias.push(new Par('UNESCO - Lógica', 'UNESCO - Lógica', false));
  //   this.categorias.push(new Par('UNESCO - Matemáticas', 'UNESCO - Matemáticas', false));
  //   this.categorias.push(new Par('UNESCO - Astronomía y Astrofísica', 'UNESCO - Astronomía y Astrofísica', false));
  //   this.categorias.push(new Par('UNESCO - Física', 'UNESCO - Física', false));
  //   this.categorias.push(new Par('UNESCO - Química', 'UNESCO - Química', false));
  //   this.categorias.push(new Par('UNESCO - Ciencias de la Vida', 'UNESCO - Ciencias de la Vida', false));
  //   this.categorias.push(new Par('UNESCO - Ciencias de la Tierra y del Espacio', 'UNESCO - Ciencias de la Tierra y del Espacio', false));
  //   this.categorias.push(new Par('UNESCO - Ciencias Agrarias', 'UNESCO - Ciencias Agrarias', false));
  //   this.categorias.push(new Par('UNESCO - Ciencias Médicas', 'UNESCO - Ciencias Médicas', false));
  //   this.categorias.push(new Par('UNESCO - Ciencias Tecnológicas', 'UNESCO - Ciencias Tecnológicas', false));
  //   this.categorias.push(new Par('UNESCO - Antropología', 'UNESCO - Antropología', false));
  //   this.categorias.push(new Par('UNESCO - Demografía', 'UNESCO - Demografía', false));
  //   this.categorias.push(new Par('UNESCO - Ciencias Económicas', 'UNESCO - Ciencias Económicas', false));
  //   this.categorias.push(new Par('UNESCO - Geografía', 'UNESCO - Geografía', false));
  //   this.categorias.push(new Par('UNESCO - Historia', 'UNESCO - Historia', false));
  //   this.categorias.push(new Par('UNESCO - Ciencias Jurídicas y Derecho', 'UNESCO - Ciencias Jurídicas y Derecho', false));
  //   this.categorias.push(new Par('UNESCO - Lingüística', 'UNESCO - Lingüística', false));
  //   this.categorias.push(new Par('UNESCO - Pedagogía', 'UNESCO - Pedagogía', false));
  //   this.categorias.push(new Par('UNESCO - Ciencia Política', 'UNESCO - Ciencia Política', false));
  //   this.categorias.push(new Par('UNESCO - Psicología', 'UNESCO - Psicología', false));
  //   this.categorias.push(new Par('UNESCO - Ciencias de las Artes y las Letras', 'UNESCO - Ciencias de las Artes y las Letras', false));
  //   this.categorias.push(new Par('UNESCO - Sociología', 'UNESCO - Sociología', false));
  //   this.categorias.push(new Par('UNESCO - Ética', 'UNESCO - Ética', false));
  //   this.categorias.push(new Par('UNESCO - Filosofía', 'UNESCO - Filosofía', false));
  //
  //   // this.old_categorias = this.copyPares(this.categorias);
  //
  // }

  public initFechas() {

    // this.fechaIni = new Date('1946-01-01T00:00:00.000Z').toISOString();
    // this.fechaIni = new Date(Date.now()).toString();
    this.fechaConsulta = new Date().toISOString();
   // this.initFechaFin();
  }
  // public initFechaFin() {
  //   let d: Date;
  //   d =  new Date(Date.now());
  //   if (d.getMonth() < 10) {
  //     this.curso = d.getFullYear();
  //   } else {
  //     this.curso = d.getFullYear() + 1;
  //   }
  //   this.fechaFin = new Date(this.curso.toString() + '-10-01T23:59:59.000Z').toISOString();
  //   // this.old_fechaFin = new Date(this.curso.toString() + '-10-01T23:59:59.000Z').toISOString();
  //
  // }

  public getnoActivedFilters(): boolean {
    return this.noActivedFilters;
  }

  public addExpiredFilter() {
    if (this.expired[0].checked) {
      this.updateMessage();
      this.noActivedFilters = false;
    }
  }

  public updateMessage() {
    this.filtersMsg = '';

    if (
        this.fechaConsulta !== this.oldFechaConsulta
    ) {
      this.filtersMsg = this.filtersMsg + ' Fecha,';
    }

   /* if (this.getAutor()) {
      this.filtersMsg = this.filtersMsg + ' Autor,';
    }

    if (this.CATEGORIAS_PLAYER[0] || this.CATEGORIAS_FILTERS[0]) {
      this.filtersMsg = this.filtersMsg + ' Categorías,';
    }

    if (this.ASIGNATURAS_PLAYER[0] || this.ASIGNATURAS_FILTERS[0]) {
      this.filtersMsg = this.filtersMsg + ' Asignaturas,';
    }

    if (this.TITULACIONES_PLAYER[0] || this.TITULACIONES_FILTERS[0]) {
      this.filtersMsg = this.filtersMsg + ' Titulaciones,';
    }

    if (
        this.fechaIni !== this.oldFechaIni ||
        this.fechaFin !== this.oldFechaFin
    ) {
      this.filtersMsg = this.filtersMsg + ' Fechas,';
    }

    if (this.repositorios instanceof Array && this.repositorios.length > 0) {
      if (this.repositorios[0].checked !== this.repositorios[1].checked) {
        this.filtersMsg = this.filtersMsg + ' Repositorios,';
      }
    }

    if (this.GENEROS_FILTERS[0]) {
      this.filtersMsg = this.filtersMsg + ' Géneros,';
    }
    */
    if (this.expired[0].checked) {
      this.filtersMsg = this.filtersMsg + ' Aulas pasadas,';
    }

    // Esta operación se realiza siempre. Elimina el último caracter del mensaje
    this.filtersMsg = this.filtersMsg.substr(0, this.filtersMsg.length - 1);

    if (this.filtersMsg.length < 1) {
      this.noActivedFilters = true;
    }
  }

  // public initGeneros() {
  //   this.generos = [];
  //   this.generos.push(new Par('Actos - Jornada', 'Actos - Jornada', false));
  //   this.generos.push(new Par('Noticias', 'Noticias', false));
  //   this.generos.push(new Par('Actos - Congreso', 'Actos - Congreso', false));
  //   this.generos.push(new Par('Reportaje/Documental/Divulgativo', 'Reportaje/Documental/Divulgativo', false));
  //   this.generos.push(new Par('Entrevista / Testimonio / Debate / Coloquio', 'Entrevista / Testimonio / Debate / Coloquio', false));
  //   this.generos.push(new Par('Actos - Curso, Máster, Experto, etc..', 'Actos - Curso, Máster, Experto, etc..', false));
  //   this.generos.push(new Par('Actos - Conferencia', 'Actos - Conferencia', false));
  //   this.generos.push(new Par('Actos - Seminario', 'Actos - Seminario', false));
  //   this.generos.push(new Par('Videoclase', 'Videoclase', false));
  //   this.generos.push(new Par('Promocional', 'Promocional', false));
  //   this.generos.push(new Par('Actos - Otros Actos', 'Actos - Otros Actos', false));
  //   this.generos.push(new Par('Actos - Videoconferencia', 'Actos - Videoconferencia', false));
  //   this.generos.push(new Par('Actos - Sesiones de trabajo', 'Actos - Sesiones de trabajo', false));
  //   this.generos.push(new Par('Actos - Talleres', 'Actos - Talleres', false));
  //   this.generos.push(new Par('Actos - Institucionales', 'Actos - Institucionales', false));
  //   this.generos.push(new Par('Actos - Encuentros', 'Actos - Encuentros', false));
  //   this.generos.push(new Par('Actos', 'Actos', false));
  //
  //   // this.old_generos = this.copyPares(this.generos);
  // }

  public initFilters() {
    if (!this.initialized) {
      this.initExpired();
      this.initFechas();

      this.initialized = true;
    }
/*
    if (this.userService.getState()===User.AUTHENTICATED) {
      this.initRepositorios();
      this.initCategorias();
      this.initFechas();
      this.initGeneros();
      this.getDatosMatriculaSolr().subscribe(
          res=> {
            this.matriculaSolr = new MatriculaSolr(res);
            this.asignaturas = this.matriculaSolr.getParAsignaturas().slice(0);
            this.old_asignaturas=this.copyPares(this.asignaturas);
            this.titulaciones = this.matriculaSolr.getParTitulaciones().slice(0);
            this.old_titulaciones=this.copyPares(this.titulaciones);
            this.updateMessage();
            this.stateFilters.next();
          });
    }
    else {*/
   //    this.initAsignaturas();
   //   this.titulaciones=[];
   //   this.old_titulaciones=[];
      // this.initRepositorios();
   //    this.initCategorias();
      // this.initExpired();
      // this.initFechas();
     // this.stateFilters.next();
      // this.initGeneros();
    //  this.updateMessage();
    //  this.stateFilters.next();
   // }
  }
  // public updateCheck(value: Par) {
  //   console.log('updateCheck', value);
  //   value.checked = !value.checked;
  //   console.log('updateCheck repositorios', this.repositorios, 'categorias', this.categorias, 'generos', this.generos);
  //
  //   // this.optionFilters="";
  // }
  // public getRepositorioCU(): boolean {
  //   if (this.repositorios) {
  //       return this.repositorios[1].checked;
  //   } else {
  //     return false;
  //   }
  // }
 public setCheckedAll(value: boolean) {
     let i: number;
     if (!value) {
       this.optionFilters = 'ninguno';
       for (i = 0; i < this.expired.length; i++) {
         this.expired[i].checked = value;
       }

//       for (i = 0; i < this.titulaciones.length; i++) {
//         this.titulaciones[i].checked = value;
//       }
//       for (i = 0; i < this.asignaturas.length; i++) {
//         this.asignaturas[i].checked = value;
//       }*/
//        for (i = 0; i < this.categorias.length; i++) {
//         this.categorias[i].checked = value;
//       }
//        for (i = 0; i < this.generos.length; i++) {
//         this.generos[i].checked = value;
//       }
//       /*
//       this.TITULACIONES_PLAYER = [];
//       this.TITULACIONES_PLAYER = [];
//       this.AUTORES_PLAYER = [];
//       this.CATEGORIAS_PLAYER = [];
//       this.resetAll();
//       this.initFechas();

     } else {
       this.optionFilters = 'alguno';
     }
    /*if (this.asignaturaP) {
      this.asignaturaP.checked = value;
    }
    if (this.titulacionP) {
      this.titulacionP.checked = value;
    }
    if (this.autorP) {
      this.autorP.checked = value;
    }
    if (this.categoriaP) {
      this.categoriaP.checked = value;
    }*/
  }
   public setCheckedRecomended() {
//     let i: number;
//
//     for (i = 0; i < this.repositorios.length; i++) {
//       this.repositorios[i].checked = true;
//     }
//    /* this.optionFilters = 'alguno';
//     for (i = 0; i < this.asignaturas.length; i++) {
//       this.asignaturas[i].checked = true;
//     }
//     for (i = 0; i < this.titulaciones.length; i++) {
//       this.titulaciones[i].checked = false;
//     }*/
//     for (i = 0; i < this.categorias.length; i++) {
//       this.categorias[i].checked = false;
//     }
//     for (i = 0; i < this.generos.length; i++) {
//       this.generos[i].checked = false;
//     }
//     /*
//     if (this.asignaturaP) {
//       this.asignaturaP.checked = false;
//     }
//     if (this.titulacionP) {
//       this.titulacionP.checked = false;
//     }
//     if (this.autorP) {
//       this.autorP.checked = false;
//     }
//     if (this.categoriaP) {
//       this.categoriaP.checked = false;
//     }*/
   }

   setOptionProcessDetails(option, value) {
     this.processData = value;
   }
   getOptionProcessDetails() {
       if (!this.processData) {
         this.processData = {
           selectedAssetsExpired: this.expired.map(c => c.checked),
           // selectedAssetsCategorias: this.categorias.map(c => c.checked),
           // selectedAssetsRepositorios: this.repositorios.map(c => c.checked),
           selectedAssetFechaConsulta: this.fechaConsulta,
           /*selectedAssetFechaFin: this.fechaFin,
           selectedAssetsTitulaciones: this.titulaciones.map(c => c.checked),
           selectedAssetsAsignaturas: this.asignaturas.map(c => c.checked),
           selectedAssetAutorP: this.autorP,
           selectedAssetTitulacionP: this.titulacionP,
           selectedAssetAsignaturaP: this.asignaturaP,
           selectedAssetCategoriaP: this.categoriaP,*/
         };
       }
       return this.processData;
   }
  setBtnOption(option, value) {
    this.btnData = value;
  }
  getBtnOption() {
    return this.btnData;
  }
  public getSortParam(): string {
    if (this.sortParam) {
      return this.sortParam;
    } else {
      return '';
    }
  }
  public setSortParam(sortParam: string) {
    console.log('Setting sort param: ' + sortParam);
    this.sortParam = sortParam;
  }
}
