import {SalaSolr} from './sala-solr';

export class SalaSolrResult {

    public salasSolr: SalaSolr[];
    public numFound: number;
    public maxScore: number;
    public start: number;

    constructor(public src: any, fechaConsulta: Date) {
        this.salasSolr = src.response.docs.map( (doc)  => SalaSolr.parse(doc, fechaConsulta) );
        this.numFound = src.response.numFound;
        this.maxScore = src.response.maxScore;
        this.start = src.response.start;

    }
    public getSalasSolr(): SalaSolr[] {
        return this.salasSolr;
    }
    public getNumFound(): number {
        return this.numFound;
    }
    public getMaxScore(): number {
        return this.maxScore;
    }
    public getStart(): number {
        return this.start;
    }
}
