import {Injectable} from '@angular/core';

@Injectable()
export class BrowserService {

  constructor() {
  }

  public reload() {
    location.reload();
  }

  public goTo(url) {
    location.href = url;
  }
}
