

import { Injectable } from '@angular/core';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import packageJson from './../../../package.json';

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  public urlServer: string;
  public version = packageJson.version;
  public build: string;
  public packageName: string;
  public deviceModel: string;
  public devicePlatform: string;
  public deviceUUID: string;
  public deviceVersion: string;
  public deviceManufacturer: string;
  public deviceSerial: string;

  public constructor(public http: HttpClient) {
    this.urlServer = environment.apiUrl;
  }

  public getUrlServer() {
    return this.urlServer;
  }

  public getVersion() {
    // TODO: Pendiente definir el build
    if (!environment.production) {
      // return this.version + ' (Build ' + this.build + ') (entorno desarrollo)';
      return this.version + ' (entorno desarrollo)';
    } else {
      return this.version;
      // return this.version + ' (Build ' + this.build + ')';
    }
  }

  public getVersionNumber() {
    return this.version;
  }

  public getIDApp() {
    switch (this.packageName) {
      case 'es.uned.intecca.calificaciones':
        return 1;
      case 'es.uned.intecca.notificaciones':
        return 2;
      case 'air.es.uned.intecca.MeetingMobile':
        return 3;
      case 'es.uned.intecca.MeetingMobile':
        return 3;
      default:
        return -1;
    }
  }

  public getUpdateInfo(): Observable<any> {
    const body = JSON.stringify({
      id_app: this.getIDApp(),
      system: this.devicePlatform,
    });

    const url: string = this.urlServer + '/apps/version';
    return this.http.post(url, body);
  }
}
