import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatoVisitas'
})
export class FormatoVisitasPipe implements PipeTransform {

  transform(visitas: number): string {
    // Agregar las unidades que se deseen
    const units = [
      {value: 1, symbol: ''},
      {value: 1E3, symbol: 'k'},
      {value: 1E6, symbol: 'M'}
    ];
    // Reconoce y agrupa los números sin los ceros adicionales (trailing zeroes)
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = units.length - 1; i > 0; i--) {
      // Si se quiere tener en cuenta números negativos usar: Math.abs(visitas)
      if (visitas >= units[i].value) {
        break;
      }
    }
    // Se decide usar solo un decimal, si se desea modificar cambiar el valor de toFixed(1)
    return (visitas / units[i].value).toFixed(1).replace(rx, '$1') + units[i].symbol;
  }
}
