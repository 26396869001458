import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'recordingsFilter'
})
export class RecordingsFilterPipe implements PipeTransform {

  transform(data: any[], text: string, campo: string): any {
    if (text === '') {
      return data;
    }
    text = text.toLowerCase();
    return data.filter( item => item[campo].toLowerCase().includes( text ));
  }

}
