

import {Util} from '../helpers/util';
import * as moment from 'moment';
import 'moment-timezone';

export class SalaSolr {
  public id: number;
  public ID_Sala: number;
  public Nombre: string ;
  public Nombre_t: string ;
  public Nombre_t_hl: string[];
  public ibactivo: boolean ;
  public Autor: string ;
  public Autor_t: string ;
  public Autor_t_hl: string[] ;
  public Descripcion: string;
  public Descripcion_t_hl: string[];
  public Directo_chat: boolean ;
  public Email_Usuario_1: string[];
  public Fecha_Fin: Date;
  public Fecha_Inicio: Date;
  public Fecha_Insercion: string;
  public Fechas_Activacion: string;
  public Grabaciones_Diferido: number;
  public ID_Recurso: number;
  public ID_Relacion: number[] ;
  public ID_Servidor: number;
  public ID_Tipos_Recurso: number;
  public ID_Tipos_Sala: number;
  public ID_Tutorias: string ;
  public ID_Usuario_1: number[];
  public Invitados_Count: number;
  public Invitados_Nombre: string[];
  public Limite_Tiempo: number;
  public MAX_Ancho_Banda: number;
  public MAX_Pods: number;
  public MAX_Usuarios: number ;
  public Nivel_Registro: string ;
  public Observaciones: string;
  public ObservacionesParticipantes: string[];
  public Observaciones_t_hl: string[];
  public PSW_Invitado: string;
  public PSW_Moderador: string;
  public Puntos_Clave: string;
  public Recurrencias_Count: number;
  public Recurrencias_Fecha: string[] ;
  public Recurrencias_Rangos: string[] ;
  public Tipos_Recurso_Descripcion: string ;
  public Tipos_Recurso_Nombre: string ;
  public Tipos_Sala_Descripcion: string;

  public autor: string;
  public fechaD: Date;
  public fechaS: string;
  public programacion: object[];
  public programacionEnCurso: any;
  public programacionSiguiente: any;
  public expired: boolean;
  public isOnline: boolean;
  public sessionsEnded: boolean;

  public aulaEspacio: string;
  public emite: string;
  public recibe: string;



  /*    public Enlace_ss: string[];
  public Tipo_ss: string[];
  public Nombres_ss: string[];
  public Usuarios_Nombre_txt: string[];
  public Fecha_dt: string;
  public ID_Grabacion: string;
  public Duracion_i: number;
  public COD_ASIGNATURA_ss: string[] = [];
  public ASIGNATURA_txt: string[] = [];
  public COD_TITULACION_ss: string[] = [];
  public TITULACION_txt: string[] = [];
  public COD_FACULTAD_ss: string[] = [];
  public FACULTAD_txt: string[] = [];
  public Salas_Nombre_t = '';
  public Nombre_Tipo_Recurso_Sala_t = '';
  public Nombre_Grupos_Grabacion_txt: string[];
  public Autor_txt: string[] = [];

  public Num_Visitas_i: number;
  public Salas_Descripcion_t = '';
  public Licencia_s = '';
  public Descripcion_t = '';
  public Publicar_MP4_i: number;
  public Genero_t: string;
  public Miniatura_s: string;
  public UID_s: string;

  public mostrar: boolean;
  public documentos: Documento[];
  public transcripcion: string;
  public subtitulo: string;
  public audiomp3: string;
  public src: string;
  public autor: string;
  public fechaD: Date;
  public fechaS: string;
  public deberiaEstarTranscodificado: boolean;
  public clasificacion: string;
  public img: string;
  public titulo: string;
  public palabras_clave: string;
  public noVideo = true;
  public noAudio = true;
  public urlLicencia: string;
*/
  public static parse(src, fechaConsulta) {
    const result = new SalaSolr();
    moment.locale('es');

    const now = moment(fechaConsulta);

    result.ID_Sala = src.ID_Sala;

    if (src.hasOwnProperty('Nombre')) {
      result.Nombre = src.Nombre;
    } else {
      result.Nombre = '';
    }

    if (src.hasOwnProperty('Descripcion')) {
      result.Descripcion = src.Descripcion;
    }

    if (src.hasOwnProperty('Observaciones') && src.Observaciones !== null) {
      const trozos = src.Observaciones.split('|');
      trozos.map(trozo => {
        if (trozo.indexOf('Espacio:') > 0) {
          result.aulaEspacio = trozo.replace('Espacio:', '').trim();
        }
        if (trozo.indexOf('Emite->') > 0) {
          result.emite = trozo.replace('Emite->', '').trim();
        }
        if (trozo.indexOf('Reciben->') > 0) {
          result.recibe = trozo.replace('Reciben->', '').trim();
        }

        if (!(trozo.indexOf('Espacio:') > 0) && !(trozo.indexOf('Emite->') > 0) && !(trozo.indexOf('Reciben->') > 0) ) {
          result.Observaciones = trozo.trim();
        }
      });
    }

    if (src.hasOwnProperty('Puntos_Clave')) {
      result.Puntos_Clave = src.Puntos_Clave;
    }

    if (src.hasOwnProperty('ibactivo')) {
      result.ibactivo = src.ibactivo;
    }
    if (src.hasOwnProperty('ID_Tipos_Sala')) {
      result.ID_Tipos_Sala = src.ID_Tipos_Sala;
    }


    if (src.hasOwnProperty('Tipos_Sala_Descripcion')) {
      result.Tipos_Sala_Descripcion = src.Tipos_Sala_Descripcion;
    }

    if (src.hasOwnProperty('Tipos_Recurso_Nombre')) {
      result.Tipos_Recurso_Nombre = src.Tipos_Recurso_Nombre;
    }
    if (src.hasOwnProperty('Tipos_Recurso_Descripcion')) {
      result.Tipos_Recurso_Descripcion = src.Tipos_Recurso_Descripcion;
    }
    if (src.hasOwnProperty('ID_Recurso')) {
      result.ID_Recurso = src.ID_Recurso;
    }
    if (src.hasOwnProperty('ID_Tipos_Recurso')) {
      result.ID_Tipos_Recurso = src.ID_Tipos_Recurso;
    }


    if (src.hasOwnProperty('Fecha_Inicio')) {
      result.Fecha_Inicio = Util.dateLocalSpainToLocal(src.Fecha_Inicio).toDate();
    }

    if (src.hasOwnProperty('Fecha_Fin')) {
      result.Fecha_Fin = Util.dateLocalSpainToLocal(src.Fecha_Fin).toDate();

      result.expired = false;
      if (moment(result.Fecha_Fin).diff(now) < 0) {
        result.expired = true;
      }
    }

    if (src.hasOwnProperty('Fechas_Activacion')) {
      result.Fechas_Activacion = src.Fechas_Activacion;

      result.programacion = [];
      result.isOnline = false;
      result.sessionsEnded = false;

      const infoRecurrencia = result.Fechas_Activacion.split(/@@,?/);
      const infoRecurrenciaPasada = result.Fechas_Activacion.split(/&&,?/);

      // las últimas están vacias o sobran
      infoRecurrencia.pop();
      infoRecurrenciaPasada.pop();

      infoRecurrencia.forEach((session) => {
        const rec = session.split('|');
        const desde = Util.dateLocalSpainToLocal(rec[0].trim());
        const hasta = Util.dateLocalSpainToLocal(rec[1].trim());

        let prefix = '';
        let postfix = '';
        let texto = '';
        if (desde.diff(now) < 1) {
            texto = 'Activa desde ';
            prefix = 'hace ';
          }
        if (desde.diff(now) >= 1) {
            texto = 'Comienza dentro ';
            prefix = ' de ';
          }

        if (hasta.diff(now) < 1) {
            postfix = ' finalizó hace ';
          }

        if (hasta.diff(now) >= 1) {
            postfix = ' hasta dentro de ';
          }
        if (desde.diff(now) < 0 && hasta.diff(now) < 0  ) {
            result.sessionsEnded = true;
            texto = 'Última sesión ';
          }
        if (result.expired && desde.diff(now) < 0 && hasta.diff(now) < 0  ) {
            result.sessionsEnded = true;
            texto = 'Última sesión ';
          }

        result.programacion.push({
            diffDesde: desde.diff(now),
            diffHasta: hasta.diff(now),
            desde,
            hasta,
            msg:
                // desde.format('DD-MM-YYYY HH:mm Z') +
                // ' al ' + hasta.format('DD-MM-YYYY HH:mm Z') + ' | ' +
                texto + prefix + desde.from(now, true) + ' ' +
                (hasta.diff(now) >= 0 ? postfix +  hasta.to(now, true) : '')
                // + ' ( vista para el ' + now.format('DD-MM-YYYY HH:mm Z') + ') '
                // + ' | ' + 'Duración ' + desde.to( hasta, true)
            ,
            alert: rec[2].trim()
        });

        if (now.isBetween(desde, hasta)) {
          result.isOnline = true;
        }
      });
      infoRecurrenciaPasada.forEach((session) => {
        const rec = session.split('|');
        const desde = Util.dateLocalSpainToLocal(rec[0].trim());
        const hasta = Util.dateLocalSpainToLocal(rec[1].trim());

        let prefix = '';
        let postfix = '';
        let texto = '';
        if (desde.diff(now) < 1) {
            texto = 'Activa desde ';
            prefix = 'hace ';
          }
        if (desde.diff(now) >= 1) {
            texto = 'Comienza dentro ';
            prefix = ' de ';
          }

        if (hasta.diff(now) < 1) {
            postfix = ' finalizó hace ';
          }

        if (hasta.diff(now) >= 1) {
            postfix = ' hasta dentro de ';
          }
        if (desde.diff(now) < 0 && hasta.diff(now) < 0) {
            texto = 'Última sesión ';
            result.sessionsEnded = true;
          }
        if (result.expired && desde.diff(now) < 0 && hasta.diff(now) < 0) {
            texto = ' Última sesión ';
            result.sessionsEnded = true;
          }

        result.programacion.push({
            diffDesde: desde.diff(now),
            diffHasta: hasta.diff(now),
            desde,
            hasta,
            msg:
                // desde.format('DD-MM-YYYY HH:mm Z') +
                // ' al ' + hasta.format('DD-MM-YYYY HH:mm Z') + ' | ' +
                texto + prefix + desde.from(now, true) + ' ' +
                (hasta.diff(now) >= 0 ? postfix + hasta.to(now, true) : '')
                // + ' (vista para el ' + now.format('DD-MM-YYYY HH:mm Z') + ') '
                // + ' | ' + 'Duración ' + desde.to(hasta, true)
            ,
            alert: rec[2].trim()
            });

        if (now.isBetween(desde, hasta)) {
          result.isOnline = true;
        }
      });
     // console.error ('DEMO prog');
      // console.log ( result);
      // console.log ( result.programacion);
     // console.error ('DEMO prog1',  infoRecurrencia);
     // console.error ('DEMO prog2',  infoRecurrenciaPasada);
      result.programacionEnCurso = result.showProgramacionEnCurso();
      result.programacionSiguiente = result.showProgramacionSiguiente();
    } else {
      result.Fechas_Activacion = '';
      result.programacion = [];
      result.programacionEnCurso = result.showProgramacionEnCurso();
      result.programacionSiguiente = result.showProgramacionSiguiente();
    }
    // if (src.hasOwnProperty('Recurrencias_Fechas')) {
    //     result.Recurrencias_Fechas = src.Recurrencias_Fechas;
    // } else {
    //     result.Recurrencias_Fechas = '';
    // }
    // if (src.hasOwnProperty('Recurencia_Rangos')) {
    //     result.Recurencia_Rangos = src.Recurencia_Rangos;
    // } else {
    //     result.Recurencia_Rangos = '';
    // }

    if (src.hasOwnProperty('Autor')) {
      result.Autor = src.Autor;
    } else {
      result.Autor = '';
    }
    if ((src.hasOwnProperty('Email_Usuario_1')) && (src.Email_Usuario_1 instanceof Array) && (src.Email_Usuario_1.length > 0)) {
      result.Email_Usuario_1 = result.setArrays(src.Email_Usuario_1.slice(0));
    } else {
      result.Email_Usuario_1 = [];
    }
    result.orderAutores(result.Email_Usuario_1);

    if ((src.hasOwnProperty('ID_Usuario_1')) && (src.ID_Usuario_1 instanceof Array) && (src.ID_Usuario_1.length > 0)) {
      result.ID_Usuario_1 = result.setArrays(src.ID_Usuario_1.slice(0));
    } else {
      result.ID_Usuario_1 = [];
    }

    if ((src.hasOwnProperty('ID_Relacion')) && (src.ID_Relacion instanceof Array) && (src.ID_Relacion.length > 0)) {
      result.ID_Relacion = result.setArrays(src.ID_Relacion.slice(0));
    } else {
      result.ID_Relacion = [];
    }

    if (src.hasOwnProperty('Invitados_Nombre')) {
      result.Invitados_Nombre = src.Invitados_Nombre;
    }


    // if ((src.hasOwnProperty('Nombre_Grupos_Grabacion_txt')) && (src.Nombre_Grupos_Grabacion_txt instanceof Array)
    //   && (src.Nombre_Grupos_Grabacion_txt.length > 0)) {
    //     result.Nombre_Grupos_Grabacion_txt = result.setArrays(src.Nombre_Grupos_Grabacion_txt.slice(0));
    // } else {
    //     result.Nombre_Grupos_Grabacion_txt = [];
    // }

    // result.orderClasificacion(result.Nombre_Grupos_Grabacion_txt);
    //       result.mostrar = true;

    return result;
  }
  // public getMostrar() {
  //     return this.mostrar;
  // }
  // public setMostrar(val: boolean) {
  //     this.mostrar = val;
  // }

  public getIDSala() {

    return this.ID_Sala;
  }


  public showInicio() {
    return Util.formatDate(this.Fecha_Inicio, 'EEEE, dd-MM-yyyy HH:mm');
  }

  public showProgramacionEnCurso() {
    if (this.programacion.length === 0) {
      return {};
    }
    // próxima sesión si diffdesde positivo más bajo
    // const a1 = this.programacion.reduce((prev: any, curr: any) => {
    //   return Math.abs(prev.diffDesde) < Math.abs(curr.diffDesde) ? prev : curr;
    // });
    //
    // console.log('en curso', this, this.programacion);
    // console.error(a1);

    // sesión en curso si diffdesde negativo más próximo a 0 y diffhasta positivo más bajo
    const enCurso: any = this.programacion.reduce((prev: any, curr: any) =>
      (Math.abs(prev.diffHasta) < Math.abs(curr.diffHasta) && prev.diffDesde < 0 ||
              Math.abs(prev.diffDesde) < Math.abs(curr.diffDesde)  && prev.diffDesde > 0) ? prev : curr);

    // console.error('en curso hasta', enCurso);
    console.log(this.ID_Sala, this.isOnline, this.expired, enCurso.msg);
    return enCurso;

  }

  public showProgramacionSiguiente() {
    if (this.programacion.length === 0) {
      return {};
    }
    // próxima sesión si diffdesde positivo más bajo
    const proxima: any = this.programacion.reduce((prev: any, curr: any) =>
      Math.abs(prev.diffDesde) < Math.abs(curr.diffDesde) && prev.diffDesde > 0 ? prev : curr);

   // console.log('Proxima', this, this.programacion);
    // console.error(a1);

    // // sesión en curso si diffdesde negativo más próximo a 0 y diffhasta positivo más bajo.
    // const b1: any = this.programacion.reduce((prev: any, curr: any) => {
    //   return Math.abs(prev.diffHasta) < Math.abs(curr.diffHasta) ? prev : curr;
    // });

    // console.warn('siguiente desde ', proxima);
    // console.assert(this.programacionEnCurso === proxima.msg, this.ID_Sala.toString(), proxima.msg);
    return proxima;
  }

  public setArrays(source) {
    if (source.length > 0) {
      return source;
    }
  }

  public getID() {
    return this.ID_Sala;
  }

  public orderAutores(autores) {
    let i: number;
    this.autor = '';
    for (i = 0; i < autores.length; i++) {
      this.autor = this.autor + ' ' + autores[i];
    }
  }
  public orderClasificacion(clasificacionSrc) {
    // let i: number;
    // this.clasificacion = '';
    // for (i = 0; i < clasificacionSrc.length; i++) {
    //     this.clasificacion = this.clasificacion + ' ' + clasificacionSrc[i];
    // }
  }
}
