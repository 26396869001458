import {concat, Observable, of, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {delay, retryWhen, switchMap, take} from 'rxjs/operators';
import {BrowserService} from '../services/browser.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {AuthService} from '../services/auth.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  constructor(public browserService: BrowserService, private router: Router, private authService: AuthService) {
  }

  // Se interceptan todas las peticiones http para permitir el envío de sesiones
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.indexOf('api.ipify.org') > -1 || request.url.indexOf('searchvoc.php') > -1) {
      request = request.clone({
      });
    } else {
      request = request.clone({
        withCredentials: true
      });
    }
    return next.handle(request).pipe(
      retryWhen(error => concat(error.pipe(
          switchMap(( httpError: any) => {
            if (httpError instanceof HttpErrorResponse) {
              if (httpError.status === 400 || httpError.status === 422 || httpError.status === 404 || httpError.status === 403) {
                // La petición no es válida, no tiene sentido repetirla
                return throwError(httpError);
              } else if (httpError.status === 401 && (! httpError.url.endsWith('/datosMatriculaBDISimple'))) {
                if (httpError.url.endsWith('/auth') || httpError.url.endsWith('/authSAML')) {
                  // No tiene permisos para acceder
                  return throwError(httpError);
                } else if (this.authService.getState() === AuthService.AUTHENTICATED) {
                  this.router.navigate(['inicio'], { state: {error: httpError.error?.error} });
                  return throwError(httpError);
                } else {
                  // TODO: hack porque el middlelwareSSO de la API devuelve 401 en localhost..
                  this.router.navigateByUrl('autenticacion' + '?redirectPage=' +
                      encodeURIComponent(encodeURIComponent(environment.portalUrl + this.router.url)));
                  return throwError(httpError);
                }
              }
            }
            return of(httpError);
          }),
          take(3),
          delay(5000)
        ), throwError({error: 'La petición ha fallado tras 3 reintentos', errNo: '500'})))
    );
  }

}
