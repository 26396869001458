

import {Injectable, OnDestroy} from '@angular/core';
import {User} from '../classes/user';
import {Observable, Subscription, throwError} from 'rxjs';
import {CA} from '../classes/ca';
import {Asignatura} from '../classes/asignatura';
import {MasterService} from './master.service';
import {EventsService} from './events.service';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Platform} from '@ionic/angular';
import {catchError, tap} from 'rxjs/operators';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {

  // Datos para las pruebas
  // Centro asociado preferido por el usuario
  CA: CA;
  idCentroAsociadoFavorito;
  idAulaFavorita;

  // Localización de la prueba: Zamora
  latitud = '42.2030917';
  longitud = '-5.266944444444444';
  radio = '50';

  // Asignaturas matrícula de la prueba. Las reales son las obtenidas del método getDatosMatricula()
  codTitulacion = -1;
  curaca;
  asignaturas: Asignatura[] = [
    /*
    {
      COD_ASIGNATURA: '71013118',
      ASIGNATURA: 'Procesadores del Lenguaje II',
    },
    {
      COD_ASIGNATURA: '71014023',
      ASIGNATURA: 'APRENDIZAJE AUTOMÁTICO',
    },
    {
      COD_ASIGNATURA: '71902083',
      ASIGNATURA: 'BASES DE DATOS',
    },
     */
  ];

  subscripciones: Subscription[] = [];

  // Info sobre el usuario obtenida de la BBDD
  private user: User = new User();

  constructor(
      public environment: MasterService,
      public eventsService: EventsService,
      public http: HttpClient,
      public storage: StorageService,
      private platform: Platform, private httpClient: HttpClient
  ) {
    // console.log('USER SERVICE INITIATED');


    const currentDate = new Date(this.user.getDate());
    if (currentDate.getMonth() < 10) {
      this.curaca = currentDate.getFullYear();
    } else {
      this.curaca = currentDate.getFullYear() + 1;
    }
  /*  this.CA = new CA();
  / this.CA.id_centro_asociado = '041000';
    this.CA.nombre = 'Instituto De Estudios Fiscales';
    const aula = new Aula();
    aula.id_aula = '041000';
    aula.nombre = 'INSTITUTO DE ESTUDIOS FISCALES';
    this.CA.aula = aula;

*/
  }

  ngOnDestroy() {
    if (this.subscripciones.length > 0) {
      this.subscripciones.forEach((s) => s.unsubscribe());
    }
  }

  public setUser(src: User) {
    // console.log('set user', src);
    this.user = src;
    this.storage.set('user', this.user)
      .then((res) => {
        this.loadUserProfile();
      });
  }

  public getUser(): User {
    // console.log('get user', this.user);
    return this.user;
  }

  public getUserEmail() {
    if (this.user) {
      return this.user.email;
    }
    return '';
  }

  public setUserEmail(email: string) {
    this.user.email = email;
  }

  public getUserCA() {
    return this.CA;
  }

  public setUserCA(ca: CA) {
    this.CA = ca;
  }

  public getUserLatitud() {
    return this.latitud;
  }

  public setUserLatitud(latitud) {
    this.latitud = latitud;
  }

  public getUserLongitud() {
    return this.longitud;
  }

  public setUserLongitud(longitud) {
    this.longitud = longitud;
  }

  public getUserRadio() {
    return this.radio;
  }

  public setUserRadio(radio) {
    this.radio = radio;
  }

  public getUserCodTitulacion() {
    return this.codTitulacion;
  }

  public setUserCodTitulacion(codTitulacion) {
    this.codTitulacion = codTitulacion;
  }

  public getUserCuraca() {
    return this.curaca;
  }

  public setUserCuraca(curaca) {
    this.curaca = curaca;
  }

  public getUserAsignaturas() {
    return this.asignaturas;
  }

  public setUserAsignaturas(asignaturas: Asignatura[]) {
    this.asignaturas = asignaturas;
  }

  // public saveUserData(
  //   email,
  //   ca,
  //   latitud,
  //   longitud,
  //   radio,
  //   codTitulacion?,
  //   curaca?,
  //   asignaturas?
  // ) {
  //   this.setUserEmail(email);
  //   this.setUserCA(ca);
  //   this.setUserLatitud(latitud);
  //   this.setUserLongitud(longitud);
  //   this.setUserRadio(radio);
  //   if (asignaturas && asignaturas.length > 0) {
  //     this.setUserAsignaturas(asignaturas);
  //   }
  //   if (codTitulacion) {
  //     this.setUserCodTitulacion(codTitulacion);
  //   }
  //   if (curaca) {
  //     this.setUserCuraca(curaca);
  //   }
  // }

  public loadUserProfile() {
    // console.log('Cargando datos del usuario ...');
    if (this.user) {
      // AUTENTICADO: Obtener los datos de la BD
      console.log('Mandando evento de carga de datos a SRAPIService ...');
      this.eventsService.publishUserProfileLoad('Load');

      this.getDatosMatriculaBDI().subscribe((datosMatricula: any) => {
        console.log('Datos matrícula: ', datosMatricula);
        const asignaturas = datosMatricula.Asignaturas;
        const titulaciones = datosMatricula.Titulaciones;
        if (asignaturas.length > 0) {
          this.asignaturas = [];
          for (const a of asignaturas) {
            const aAux = new Asignatura(a);
            console.log('Nueva asignatura: ', aAux);
            // aAux.ASIGNATURA = a.ASIGNATURA;
            this.asignaturas.push(aAux);
          }
        }
        if (titulaciones.length > 0) {
          this.codTitulacion = titulaciones[0].COD_TITULACION_s;
        }
        this.eventsService.publishDatosMatriculaEvent(EventsService.READY);
      });
    } else {
      this.asignaturas = [];
      this.codTitulacion = -1;
      this.eventsService.publishDatosMatriculaEvent(EventsService.NOT_READY);
      /*
      // NO AUTENTICADO: Obtener los datos del Storage
      this.storage.get('acc_subtitulos_busqueda').then(
          (accSubtitulosBusqueda: boolean) => {
            // console.log('Cargado elemento 1: ' + acc_subtitulos_busqueda);
            // console.log('isNull?: ' + !acc_subtitulos_busqueda);
            if (!accSubtitulosBusqueda) {
              this.user.setOptionAccessibilityValue('1', false);
            } else {
              this.user.setOptionAccessibilityValue('1', accSubtitulosBusqueda);
            }
          },
          (error) => {
            console.log(
                'Error al cargar el valor de accesibilidad especificado (1). Guardando valor predeterminado.'
            );
            this.user.setOptionAccessibilityValue('1', false);
          }
      );
      this.storage.get('acc_transcripcion_busqueda').then(
          (accTranscripcionBusqueda: boolean) => {
            if (!accTranscripcionBusqueda) {
              this.user.setOptionAccessibilityValue('2', false);
            } else {
              this.user.setOptionAccessibilityValue(
                  '2',
                  accTranscripcionBusqueda
              );
            }
          },
          (error) => {
            console.log(
                'Error al cargar el valor de accesibilidad especificado (2). Guardando valor predeterminado.'
            );
            this.user.setOptionAccessibilityValue('2', false);
          }
      );
      this.storage.get('acc_subtitulos_player').then(
          (accSubtitulosPlayer: boolean) => {
            if (!accSubtitulosPlayer) {
              this.user.setOptionAccessibilityValue('3', false);
            } else {
              this.user.setOptionAccessibilityValue('3', accSubtitulosPlayer);
            }
          },
          (error) => {
            console.log(
                'Error al cargar el valor de accesibilidad especificado (3). Guardando valor predeterminado.'
            );
            this.user.setOptionAccessibilityValue('3', false);
          }
      );
       */
    }
  }

  public saveUserProfile() {
    if (this.user) {
      // AUTENTICADO: Guardar los datos en la BD
      this.eventsService.publishUserProfileSave('Save');
    } else {
      /*
      // NO AUTENTICADO: Guardar los datos en el Storage
      this.storage.set(
          'acc_subtitulos_busqueda',
          this.user.getOptionAccessibilityValue('1')
      );
      this.storage.set(
          'acc_transcripcion_busqueda',
          this.user.getOptionAccessibilityValue('2')
      );
      this.storage.set(
          'acc_subtitulos_player',
          this.user.getOptionAccessibilityValue('3')
      );
       */
    }
  }

  public getData() {
    // : Observable<Response>
    // let headers = new Headers({'Content-Type': 'application/json'});
    // let options = new RequestOptions({headers: headers});
    const server = this.environment.getUrlServer();
    const body = null;
    return this.http
        .post(server + '/datosEstudiante', body)
        .pipe(catchError(this.handleError));
  }

  public getDatosMatricula() {
    // : Observable<Response>
    // let headers = new Headers({'Content-Type': 'application/json'});
    // let options = new RequestOptions({headers: headers});
    const server = this.environment.getUrlServer();
    const body = null;
    return (
        this.http
        // .post(server + '/datosEstudiante', body, httpOptions)
            .post(server + '/datosMatricula', body)
            .pipe(catchError(this.handleError))
    );
  }

  public getDatosMatriculaBDI() {
    if (!this.user) {
      console.log('Usuario no disponible');
      return;
    }
    // : Observable<Response>
    // let headers = new Headers({'Content-Type': 'application/json'});
    // let options = new RequestOptions({headers: headers});
    const server = this.environment.getUrlServer();
    const body = null;
    return (
      this.http
        // .post(server + '/datosEstudiante', body, httpOptions)
        .post(server + '/datosMatriculaBDISimple', body)
        .pipe(catchError(this.handleError))
    );
  }
  public isStudent(): boolean {
    return this.getUserEmail().includes('alumno');
  }
  public createUserAVIP(body: FormData): Observable<any> {
    console.log(body, this.environment.getUrlServer());

    return this.httpClient.post<any>(this.environment.getUrlServer() + '/nsr/users', body)
        .pipe(
            tap(res => {
              console.log('create user avip service: ' + JSON.stringify(res));
            })
        );
  }
  public updateUserAVIP(body: FormData): Observable<any> {
    console.log(body);

    return this.httpClient.post<any>(this.environment.getUrlServer() + '/nsr/users/me', body)
        .pipe(
            tap(res => {
              console.log('update user avip service: ' + JSON.stringify(res));
            })
        );
  }
  private handleError(error: HttpErrorResponse): Observable<Response> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('getData client error: ', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
          `getData backend error: Backend returned code ${error.status}, ` +
          `body was: `, JSON.stringify(error.error)
      );
    }
    // return an observable with a user-facing error message
    return throwError('Error en getData');
    // return Observable.throw(error);
  }
}
