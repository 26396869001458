import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private instance: Storage | null = null;
  private state: Subject<boolean> = new Subject<boolean>();

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    this.instance = await this.storage.create();
    this.state.next(true);
  }

  public set(key: string, value: any): Promise<any> {
    if (this.instance === null) {
      return new Promise((resolve, reject) => {
        const subscription = this.state.subscribe(async res => {
          if (this.instance !== null) {
            subscription.unsubscribe();
            resolve(await this.instance?.set(key, value));
          }
        });
      });
    }
    return this.instance.set(key, value);
  }

  public get(key: string): Promise<any> {
    if (!this.instance) {
      return new Promise((resolve, reject) => {
        const subscription = this.state.subscribe(async res => {
          if (this.instance) {
            subscription.unsubscribe();
            resolve(await this.instance?.get(key));
          }
        });
      });
    }
    return this.instance?.get(key);
  }
}
