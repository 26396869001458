

export class Par {
    public Nombre: string;
    public Codigo: string;
    public checked: boolean;

    constructor(c: string = '', n: string = '', ck: boolean = false) {
        this.Nombre = n;
        this.Codigo = c;
        this.checked = ck;
    }
}
export class MatriculaSolr {

    public parTitulaciones: Par[];
    public parAsignaturas: Par[];
    public parFacultades: Par[];

    constructor(src?: any) {
        // console.log("src",src);
        this.parAsignaturas = [];
        this.parTitulaciones = [];
        this.parFacultades = [];
        this.setParesAsignaturas(src.Asignaturas);
        this.setParesTitulacionesFacultades(src.Titulaciones);
    }
    public getParTitulaciones() {
        // console.log('PARES DE TITULACIONES -> ',this.parTitulaciones);
        return this.parTitulaciones;
    }
    public getParAsignaturas() {

        // console.log('PARES DE ASIGNATURAS -> ', this.parAsignaturas);
        return this.parAsignaturas;
    }
    public getParFacultades() {

        return this.parFacultades;
    }

    public setParesAsignaturas(src) {
        let i: number;
        let par;

        for (i = 0; i < src.length; i++) {
            par = new Par();
            par.Codigo = src[i].COD_ASIGNATURA;
            par.Nombre = src[i].ASIGNATURA;
            par.checked = true;
            this.parAsignaturas.push(par);
        }
        //     console.log('En set paresAsignaturas--->' , this.parAsignaturas);
    }

    public setParesTitulacionesFacultades(src) {

        let i: number;
        let par;

        for (i = 0; i < src.length; i++) {
            par = new Par();
            par.Codigo = src[i].COD_TITULACION_s;
            par.Nombre = src[i].TITULACION_t;
            this.parTitulaciones.push(par);
            par = new Par();
            par.Codigo = src[i].COD_FACULTAD_s;
            par.Nombre = src[i].FACULTAD_t;
            this.parFacultades.push(par);
        }
        // console.log('En set paresTitulaciones--->' , this.parTitulaciones);
    }

    public setArrays(source) {
        if (source.length > 0) {
            return source;
        }
    }
}
