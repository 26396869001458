import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uniqfilter'
})
export class UniqfilterPipe implements PipeTransform {
    transform(items: any[]) {
      return [...new Set(items)];
    }
}
