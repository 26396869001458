

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map, tap} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {SalaSolrResult} from '../classes/solr-sala.result';
import {SalaSolr} from '../classes/sala-solr';
import {FilterRooms} from '../classes/filterRooms';
import {RoomFilterService} from './room-filter.service';
import * as moment from 'moment';
import 'moment-timezone';
import {Util} from '../helpers/util';
import {Grabacion} from '../classes/grabacion';

@Injectable({
  providedIn: 'root'
})
export class RoomService {

  public salas: SalaSolr[] = [];
  public completeGetSalasSolr: boolean;
  public start: number;
  public querySearch: string;
  public filters: FilterRooms;
  public sort: string;
  public numFound: number;
  public currentSala: SalaSolr;
  public indexCurrentSala: number;

  constructor(public httpClient: HttpClient, private authService: AuthService, public roomfilterService: RoomFilterService) {
    this.start = 0;
    this.querySearch = '';
    this.sort = 'Fecha_Inicio';
    this.numFound = 0;
    this.currentSala = new SalaSolr();
    this.indexCurrentSala = 0;
  }

  public createRoom(body: FormData): Observable<Grabacion> {
    return this.httpClient.post<any>(environment.apiUrl + '/nsr/room/createOne', body)
      .pipe(
        tap(res => {
          console.log('create room service: ' + JSON.stringify(res));
        })
      );
  }

  public updateRoom(idSala: number, body: FormData): Observable<Grabacion> {

    return this.httpClient.post<any>(environment.apiUrl + '/nsr/room/updateOne/' + idSala, body)
      .pipe(
        tap(res => {
          console.log('update room: ' + JSON.stringify(res));
        })
      );
  }

  public deleteRoom(idSala: number): Observable<Grabacion> {

    return this.httpClient.post<any>(environment.apiUrl + '/nsr/room/deleteOne/' + idSala, {})
      .pipe(
        tap(res => {
          console.log('delete room: ' + JSON.stringify(res));
        })
      );
  }

  public getRoom(idSala: number): Observable<any> {
    const body = {};
    return this.httpClient.get<any>(environment.apiUrl + '/nsr/room/showOne/' + idSala, body)
        .pipe(
            tap(res => {
              console.log('one roomservice: ' + JSON.stringify(res));
            })
        );
  }
  /* public getUserRooms(): Observable<Sala[]> {
    const body = {};
    this.completeGetSalasSolr = false;
    // TODO
    return this.httpClient.get<Sala[]>(environment.apiUrl + '/nsr/room/showMine', body)
      .pipe(
        tap(res => {
          console.log('RoomService: ' + JSON.stringify(res));
          this.completeGetSalasSolr = true;
        })
      );
  }

  public getRoom(idSala: number): Observable<Sala> {
    // TODO: pte servicio
    const body = {};
    return this.httpClient.get<any>(environment.apiUrl + '/nsr/room/showOne/' + idSala, body)
      .pipe(
        tap(res => {
          console.log('one RoomService: ' + JSON.stringify(res));
        })
      );
  }

  public updateRecording(idSala: number, body: Sala): Observable<Sala> {
    // TODO: pte servicio
    return this.httpClient.put<any>(environment.apiUrl + '/nsr/room/updateOne/' + idSala, body)
      .pipe(
        tap(res => {
          console.log('update RoomService: ' + JSON.stringify(res));
        })
      );
  } */

  public getListadoSalasSolr(queryParam: string, filters: FilterRooms, sort: string = '',
                             start: number = 0, rows: number = 15): Observable<any> {

    let url = '';
    this.completeGetSalasSolr = false;
    this.start = start;
    console.log('Parametros para reiniciar el start-', this.querySearch, '-', queryParam, '-', this.start, this.salas.length);

    if (this.querySearch !== queryParam ) {
      this.querySearch = queryParam;
      this.start = 0;
    }

    if (this.filters !== filters) {
      this.filters = filters;
      this.start = 0;
    }
    // TODO: se resetea en el resultado del servicio.
    if (this.start === 0) {
      //  this.salas = [];
    }
    /*
    let body = JSON.stringify({query: queryParam, COD_ASIGNATURA_ss:this.homeService.getAsignaturas(),
            COD_TITULACION_ss:this.homeService.getTitulaciones(),
            Fecha_Ini_s:this.homeService.getFechaIni(), Fecha_Fin_s:this.homeService.getFechaFin(),
            ATTACHED:this.user.ATTACHED,Autor_txt:this.homeService.getAutor(),CATEGORIA_txt: this.homeService.getCategorias(),
            Repositorio_s: this.homeService.getRepositorios(),GENERO_txt: this.homeService.getGeneros(),start:this.start,
            rows: this.rows,sort: sort, filters:this.homeService.getOptionFilters()}) ;
     */
    // let body = {query: queryParam, COD_ASIGNATURA_ss: {}, COD_TITULACION_ss: {},
    //       Fecha_Ini_s: new Date('1946-01-01T00:00:00.000Z').toISOString(),
    //       Fecha_Fin_s: new Date('2021-01-01T00:00:00.000Z').toISOString(),
    //       Repositorio_s: ['CC'], GENERO_txt: {}, start, rows, sort} ;
    // if (this.querySearch === '') {
    //    body = {query: undefined, COD_ASIGNATURA_ss: {}, COD_TITULACION_ss: {},
    //         Fecha_Ini_s: new Date('1946-01-01T00:00:00.000Z').toISOString(),
    //         Fecha_Fin_s: new Date('2021-01-01T00:00:00.000Z').toISOString(),
    //         Repositorio_s: ['CC'], GENERO_txt: {}, start, rows, sort} ;
    // }

    // if (rows > 5 ) {
    //   debugger;
    // }
    const body = {
      query: undefined,

      Autor: undefined,
      ID_Usuario: undefined,

      Invitado: undefined,

      Salas_Del_Dia: undefined,
      Fecha_Inicio: undefined,
      Fecha_Fin: undefined,
      fecha: undefined,
      expired: undefined,

      start: this.start,
      rows,
      sort

    };
    // {query: queryParam,
    //   Fecha_Inicio: new Date('1946-01-01T00:00:00.000Z').toISOString(),
    //   Fecha_Fin: new Date('2021-01-01T00:00:00.000Z').toISOString(),
    //   // Autor: 'desarrollo@intecca.uned.es',
    //   // Autor: 'msantos@ponferrada.uned.es',
    //   // Invitado: 'msantos@ponferrada.uned.es',
    //   // ID_Usuario: 250,
    //   // Autor: this.authService.user.email,
    //   start: this.start, rows, sort} ;
    // if (this.querySearch === '') {
    //   body = {query: undefined,
    //     Fecha_Inicio: new Date('1946-01-01T00:00:00.000Z').toISOString(),
    //     Fecha_Fin: new Date('2021-01-01T00:00:00.000Z').toISOString(),
    //     // Autor: 'desarrollo@intecca.uned.es',
    //     // Autor: 'msantos@ponferrada.uned.es',
    //     // Invitado: 'msantos@ponferrada.uned.es',
    //     // ID_Usuario: 250,
    //     // Autor: this.authService.user.email,
    //     start: this.start, rows, sort} ;
    // }

    if (this.querySearch !== '') {
      body.query = this.querySearch;
    }

    if (filters.owner) {
      body.Autor = filters.owner;
    }
    if (filters.ownerId) {
      body.ID_Usuario = filters.ownerId;
    }

    if (filters.guest) {
      body.Invitado = filters.guest;
    }
    if (filters.view === 'showDay') {
      body.Salas_Del_Dia = Util.dateLocalToLocalSpain(filters.date).format('YYYY-MM-DD'); // .toISOString();
    } else {
      // en vista de salas propias o invitaciones, monstrar todas las activas o las obsoletas, sin tener en cuenta un
      // dia concreto.
      // body.fecha = Util.dateLocalToLocalSpain(moment(filters.date)).toISOString();
      body.fecha = filters.date;

      if (filters.expired) {
        body.expired = filters.expired;
      }
    }

    if (this.authService.authenticated) {
      // TODO: revisar
      url = environment.apiUrl + '/listadoSalasSearchSolr';
    } else {
      url = environment.apiUrl + '/listadoSalasSearchSolr';
    }
    // console.log('URL->', url);
    console.log('parametros del servicio-> ', body, '--start- ', this.start);


    if (this.filters.view === 'myRooms') {
    //  return this.getMyRooms(queryParam, filters, sort, start, rows);
    }

    return this.httpClient.post<any>(url, body)
        .pipe(
            map((res) => {
              // Calcula los mensajes en función respecto a la fecha que se pase.
              const solrResult = new SalaSolrResult(res, new Date(this.roomfilterService.fechaConsulta));

              this.numFound = solrResult.getNumFound();

              if (this.start === 0) {
                console.error('reset');
                this.salas = solrResult.getSalasSolr();
              } else {
                console.error('concat');
                this.salas.push(...solrResult.getSalasSolr());
              }

              this.salas.sort((obj1, obj2) => {
                // fecha fin pasada (inactivas) colocar abajo
                if (obj1.expired > obj2.expired ) {
                  return 1;
                }
                if (obj1.expired < obj2.expired ) {
                  return -1;
                }

                // session en curso , colocar arriba
                if (obj1.isOnline > obj2.isOnline ) {
                  return -1;
                }
                if (obj1.isOnline < obj2.isOnline ) {
                  return 1;
                }

                // sesiones finalizadas, colocar debajo.
                if (obj1.sessionsEnded > obj2.sessionsEnded ) {
                  return 1;
                }
                if (obj1.sessionsEnded < obj2.sessionsEnded ) {
                  return -1;
                }

                // si llega aqui tanto obj1 como obj1 tienen igual valor de isOnline,  sessionsEnded, inactiva

                if (!obj1.expired && !obj1.sessionsEnded) {
                  // las que estan activas y tienen alguna sesión en cuerso o próxima, ...
                  // ... las en curso  ordenar por finalización de sesion más proxima arriba.
                  if (obj1.isOnline) {
                    if (obj1.programacionEnCurso.diffHasta < obj2.programacionEnCurso.diffHasta) {
                      return -1;
                    }
                    if (obj1.programacionEnCurso.diffHasta > obj2.programacionEnCurso.diffHasta) {
                      return 1;
                    }
                  } else {
                    // ... las proximas ordenar por inicio sesion más proxima arriba.
                    if (obj1.programacionEnCurso.diffDesde < obj2.programacionEnCurso.diffDesde) {
                      return -1;
                    }
                    if (obj1.programacionEnCurso.diffDesde > obj2.programacionEnCurso.diffDesde) {
                      return 1;
                    }
                  }
                } else {
                  // las que estan inactivas por fecha fin y todas las sesiones ya han finalizado. y no tiene ptes..

                  // ..inactivas o finalizadas sesiones fecha de comienzo de sesión, antiguas al final.
                  if (obj1.programacionEnCurso.diffDesde < 0 || obj2.programacionEnCurso.diffDesde < 0) {
                    if (obj1.programacionEnCurso.diffDesde < obj2.programacionEnCurso.diffDesde) {
                      return 1;
                    }
                    if (obj1.programacionEnCurso.diffDesde > obj2.programacionEnCurso.diffDesde) {
                      return -1;
                    }
                  }
                }
              });
              console.warn( 'start:' , this.start ,  'salas cargadas;', this.salas.length,  'found:', this.numFound);

              if (rows > this.numFound || this.salas.length > this.numFound) {
                console.error('reset start mayor n result', this.salas.length , this.numFound);
                this.start = 0;
              } else {
                console.error('reset start con tamaño obj salas', this.salas.length , this.numFound);
                this.start = this.salas.length;
              }
              console.log(solrResult, this.salas, this.numFound);
              return res;
            }),
            tap((res) => {
              this.completeGetSalasSolr = true;
            }));
  }
  public setCurrentSala(current: SalaSolr, index: number) {
    this.currentSala = current;
    this.indexCurrentSala = index;
  }
  public getCurrentSala(): SalaSolr {
    return this.currentSala;
  }

  public getMyRooms(queryParam: string, filters: FilterRooms, sort: string = '',
                    start: number = 0, rows: number = 15): Observable<any> {

    this.start = start;
    /*
        if (this.querySearch !== queryParam ) {
          this.querySearch = queryParam;
          this.start = 0;
        }
    */
    const body = {
      query: this.querySearch,
      start: this.start,
      rows,
      sort,
      fecha: filters.date,
      expired: filters.expired
    };

    console.log('getmyrooms', body);
    return this.httpClient.post<any>(environment.apiUrl + '/nsr/room/searchMine', body)
      .pipe(
        map((res) => {
          console.log('ressearchmine', res);
          // Calcula los mensajes en función respecto a la fecha que se pase.
          const solrResult = new SalaSolrResult(res, new Date(this.roomfilterService.fechaConsulta));

          if (this.start === 0) {
            console.error('reset');
            this.numFound = solrResult.getNumFound();
            this.salas = solrResult.getSalasSolr();
          } else {
            console.error('concat');
            this.salas.push(...solrResult.getSalasSolr());
          }

          this.salas.sort((obj1, obj2) => {
            // fecha fin pasada (inactivas) colocar abajo
            if (obj1.expired > obj2.expired ) {
              return 1;
            }
            if (obj1.expired < obj2.expired ) {
              return -1;
            }

            // session en curso , colocar arriba
            if (obj1.isOnline > obj2.isOnline ) {
              return -1;
            }
            if (obj1.isOnline < obj2.isOnline ) {
              return 1;
            }

            // sesiones finalizadas, colocar debajo.
            if (obj1.sessionsEnded > obj2.sessionsEnded ) {
              return 1;
            }
            if (obj1.sessionsEnded < obj2.sessionsEnded ) {
              return -1;
            }

            // si llega aqui tanto obj1 como obj1 tienen igual valor de isOnline,  sessionsEnded, inactiva

            if (!obj1.expired && !obj1.sessionsEnded) {
              // las que estan activas y tienen alguna sesión en cuerso o próxima, ...
              // ... las en curso  ordenar por finalización de sesion más proxima arriba.
              if (obj1.isOnline) {
                if (obj1.programacionEnCurso.diffHasta < obj2.programacionEnCurso.diffHasta) {
                  return -1;
                }
                if (obj1.programacionEnCurso.diffHasta > obj2.programacionEnCurso.diffHasta) {
                  return 1;
                }
              } else {
                // ... las proximas ordenar por inicio sesion más proxima arriba.
                if (obj1.programacionEnCurso.diffDesde < obj2.programacionEnCurso.diffDesde) {
                  return -1;
                }
                if (obj1.programacionEnCurso.diffDesde > obj2.programacionEnCurso.diffDesde) {
                  return 1;
                }
              }
            } else {
              // las que estan inactivas por fecha fin y todas las sesiones ya han finalizado. y no tiene ptes..

              // ..inactivas o finalizadas sesiones fecha de comienzo de sesión, antiguas al final.
              if (obj1.programacionEnCurso.diffDesde < 0 || obj2.programacionEnCurso.diffDesde < 0) {
                if (obj1.programacionEnCurso.diffDesde < obj2.programacionEnCurso.diffDesde) {
                  return 1;
                }
                if (obj1.programacionEnCurso.diffDesde > obj2.programacionEnCurso.diffDesde) {
                  return -1;
                }
              }
            }
          });
          console.warn( 'start:' , this.start ,  'salas cargadas;', this.salas.length,  'found:', this.numFound);

          if (rows > this.numFound || this.salas.length > this.numFound) {
            console.error('reset start mayor n result', this.salas.length , this.numFound);
            this.start = 0;
          } else {
            console.error('reset start con tamaño obj salas', this.salas.length , this.numFound);
            this.start = this.salas.length;
          }
          console.log(solrResult, this.salas, this.numFound);
          return res;
        }),
        tap((res) => {
          this.completeGetSalasSolr = true;
        }));
  }
}
