import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecordingsFilterPipe } from './recordings-filter.pipe';
import {FormatoVisitasPipe} from './formato-visitas.pipe';
import { UniqfilterPipe } from './uniqfilter.pipe';



@NgModule({
  declarations: [
    RecordingsFilterPipe,
    FormatoVisitasPipe,
    UniqfilterPipe
  ],
  exports: [
    RecordingsFilterPipe,
    FormatoVisitasPipe,
    UniqfilterPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
