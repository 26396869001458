import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-invitations-result',
  templateUrl: './invitations-result.component.html',
  styleUrls: ['./invitations-result.component.scss'],
})
export class InvitationsResultComponent implements OnInit {

  @Input() create: any;
  @Input() delete: any;
  @Input() notify: any;

  constructor(public modalController: ModalController) {
  }

  ngOnInit() {
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
