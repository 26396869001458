// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-logo-img {
  width: 100px;
  padding-left: 10px;
}

.app-custom-cursor {
  cursor: pointer;
}

.app-custom-logo {
  width: 200px;
  margin: 10px;
}

.app-custom-card {
  text-align: center;
  background-color: #00533f;
}

.app-custom-background {
  --background: #ffffff url('/portal/fondouned50.png');
  background-size: contain;
}

.center {
  text-align: center;
  margin: auto;
}

.app-custom-width-menu {
  width: 20%;
}

ion-item:focus {
  --background: #86a699;
}

ion-item:hover {
  --background: #cddad5;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;AACF;;AACA;EACE,eAAA;AAEF;;AAAA;EACE,YAAA;EACA,YAAA;AAGF;;AADA;EACE,kBAAA;EACA,yBAAA;AAIF;;AAFA;EACE,oDAAA;EACA,wBAAA;AAKF;;AAHA;EACE,kBAAA;EACA,YAAA;AAMF;;AAJA;EACE,UAAA;AAOF;;AALA;EACE,qBAAA;AAQF;;AANA;EACE,qBAAA;AASF","sourcesContent":[".app-logo-img {\n  width: 100px;\n  padding-left: 10px;\n}\n.app-custom-cursor {\n  cursor: pointer;\n}\n.app-custom-logo {\n  width: 200px;\n  margin: 10px;\n}\n.app-custom-card {\n  text-align: center;\n  background-color: #00533f;\n}\n.app-custom-background {\n  --background: #ffffff url('../assets/images/fondouned50.png');\n  background-size: contain;\n}\n.center{\n  text-align:center;\n  margin: auto;\n}\n.app-custom-width-menu {\n  width: 20%;\n}\nion-item:focus {\n  --background: #86a699; // uned Light\n}\nion-item:hover {\n  --background: #cddad5; // uned white\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
