import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  public static READY = 1;
  public static NOT_READY = 2;

  // private listadosModificados = new Subject();
  // listadosModificados$ = this.listadosModificados.asObservable();
  private homeGrabacionPopover = new Subject();
  homeGrabacionPopover$ = this.homeGrabacionPopover.asObservable();
  private bibliotecaGrabacionPopover = new Subject();
  bibliotecaGrabacionPopover$ = this.bibliotecaGrabacionPopover.asObservable();
  private miListaGrabacionPopover = new Subject();
  miListaGrabacionPopover$ = this.miListaGrabacionPopover.asObservable();
  private playerGrabacionPopover = new Subject();
  playerGrabacionPopover$ = this.playerGrabacionPopover.asObservable();
  private resultadosGrabacionPopover = new Subject();
  resultadosGrabacionPopover$ = this.resultadosGrabacionPopover.asObservable();
  private userProfileSave = new Subject();
  userProfileSave$ = this.userProfileSave.asObservable();
  private userProfileLoad = new Subject();
  userProfileLoad$ = this.userProfileLoad.asObservable();
  private datosMatriculaEvent = new BehaviorSubject(EventsService.NOT_READY);
  datosMatriculaEvent$ = this.datosMatriculaEvent.asObservable();

  constructor() {}

  // publishListadosModificados() {
  //   this.listadosModificados.next();
  // }

  publishHomeGrabacionPopOver(ev, idGrabacion) {
    this.homeGrabacionPopover.next({ ev, idGrabacion });
  }

  publishBibliotecaGrabacionPopOver(ev, idGrabacion) {
    this.bibliotecaGrabacionPopover.next({ ev, idGrabacion });
  }

  publishMiListaGrabacionPopOver(ev, idGrabacion) {
    this.miListaGrabacionPopover.next({ ev, idGrabacion });
  }

  publishPlayerGrabacionPopOver(ev, idGrabacion) {
    this.playerGrabacionPopover.next({ ev, idGrabacion });
  }

  publishResultadosGrabacionPopOver(ev, idGrabacion) {
    this.resultadosGrabacionPopover.next({ ev, idGrabacion });
  }

  publishUserProfileSave(obj) {
    this.userProfileSave.next(obj);
  }

  publishUserProfileLoad(obj) {
    console.log('Nuevo evento de carga de perfil de usuario ...');
    this.userProfileLoad.next(obj);
  }

  publishDatosMatriculaEvent(obj) {
    this.datosMatriculaEvent.next(obj);
  }

  getDatosMatriculaEvent() {
    return this.datosMatriculaEvent.getValue();
  }
}
