

export class Asignatura {
    COD_ASIGNATURA: string;
    ASIGNATURA: string;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}
