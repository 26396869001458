import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActionSheetController, IonicSafeString, ModalController, ToastController} from '@ionic/angular';
import {RoomFilterService} from '../../services/room-filter.service';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Par} from '../../classes/matricula-solr';
import {FilterService} from '../../services/filter.service';
import {Asignatura} from '../../classes/asignatura';
import {Titulacion} from '../../classes/titulacion';
import {Router} from '@angular/router';
import {RoomService} from '../../services/room.service';

@Component({
  selector: 'app-room-filters',
  templateUrl: './room-filters.component.html',
  styleUrls: ['./room-filters.component.scss'],
})
export class RoomFiltersComponent implements OnInit {
  public noActivedFilters: boolean;

  public viaTitulacion;
  public codTitulacion;
  public curaca;
  nombreTitulacion;

  asignaturas: Asignatura[];

  // Genera cursos académicos desde 2010 a 2020.
  listadoCuraca: number[] = Array.from(Array(11), (_, i) => i + 2010);
  listadoViasTitulacion: string[] = [];
  listadoTitulaciones: Titulacion[] = [];
  listadoAsignaturasTitulacionAnyo: Asignatura[] = [];
  loadingCuraca = true;
  loadingListadoViasTitulacion = true;
  loadingListadoTitulaciones = true;
  loadingListadoAsignaturasTitulacionAnyo = true;

  public placeHolder: string;

  loadingRooms = true;
  // Para poder modificar las fechas de los filtros antes de mandarlas al Home Service
 //  customPickerOptionsFechaIni: any;
  // customPickerOptionsFechaFin: any;

  public querySearch: string;

  desplegables = [
    {
      seccion: 'Anteriores',
      desplegado: true,
    },
    {
      seccion: 'Asignaturas',
      desplegado: false,
    },
    {
      seccion: 'Antiguas',
      desplegado: true,
    },
    {
      seccion: 'Fechas',
      desplegado: true,
    },
    {
      seccion: 'Categorias',
      desplegado: false,
    },
    {
      seccion: 'Generos',
      desplegado: false,
    },
  ];

  filterForm: UntypedFormGroup;

  public dataProcessDetail;

  public assetsExpired: Par[] = [];
  public assetsCategorias: Par[] = [];
  public assetsGeneros: Par[] = [];
  // public assetFechaIni: string;
  public assetFechaConsulta: string;
  public assetFechaFin: string;
  public assetsTitulaciones: Par[] = [];
  public assetsAsignaturas: Par[] = [];
  public assetAutorP: Par;
  public assetTitulacionP: Par;
  public assetAsignaturaP: Par;
  public assetCategoriaP: Par;
  public assetCuraca: string;
  public assetViaTitulacion: string;
  public assetCodTitulacion: string;
  public assetsOtrasAsignaturas: Asignatura[] = [];
  public selectedTextAsignatura: string;

  private selectedAssetsExpired;
  private selectedAssetsCategorias;
  private selectedAssetsGeneros;
  // private selectedAssetFechaIni;
  private selectedAssetFechaConsulta;
  private selectedAssetFechaFin;
  private selectedAssetsTitulaciones;
  private selectedAssetsAsignaturas;
  private selectedAssetAutorP;
  private selectedAssetAsignaturaP;
  private selectedAssetCategoriaP;
  private selectedAssetTitulacionP;
  private selectedAssetCuraca;
  private selectedAssetViaTitulacion;
  private selectedAssetCodTitulacion;
  private selectedAssetsOtrasAsignaturas;

  constructor( private router: Router,
               private formBuilder: UntypedFormBuilder,
               private changeDetectorRef: ChangeDetectorRef,
               public actionSheetController: ActionSheetController,
               public toastController: ToastController,
               public roomService: RoomService,
               public modalController: ModalController,
               public roomFilterService: RoomFilterService) { }

  ngOnInit() {

    /*this.customPickerOptionsFechaIni = {
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {
            console.log('Cancelar seleccionado');
          },
        },
        {
          text: 'Aceptar',
          handler: (data) => {
            console.log('Aceptar seleccionado.');
            console.log(data);
            let dateString =
                data.year.text +
                '-' +
                data.month.text +
                '-' +
                data.day.text +
                'T23:59:59.000Z';
            // console.log('Nueva fecha ini: ', dateString);
            const dateString = new Date(Date.now()).toString();
            console.log('FECJA ->', dateString);
            this.assetFechaIni = dateString;
            this.filterForm.get('assetFechaIni').setValue(dateString);
          },
        },
      ],
    };*/

   /* this.customPickerOptionsFechaFin = {
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {
            console.log('Cancelar seleccionado');
          },
        },
        {
          text: 'Aceptar',
          handler: (data) => {
            console.log('Aceptar seleccionado.');
            console.log(data);
            const dateString =
                data.year.text +
                '-' +
                data.month.text +
                '-' +
                data.day.text +
                'T23:59:59.000Z';
            console.log('Nueva fecha fin: ', dateString);
            this.assetFechaFin = dateString;
            this.filterForm.get('assetFechaFin').setValue(dateString);
          },
        },
      ],
    };*/
   this.noActivedFilters = this.roomFilterService.getnoActivedFilters();
  /*  const stateFiltersSubscription: Subscription = this.roomFilterService.stateFilters$.subscribe(
        (res) => {
          stateFiltersSubscription.unsubscribe();

         /* this.roomFilterService.ASIGNATURAS_FILTERS = [];
          for (const auxAsignatura of this.roomFilterService.asignaturas) {
            if (auxAsignatura.checked) {
              this.roomFilterService.addAsignaturaFilter(
                  auxAsignatura.Codigo
              );
            }
          }
        }
    );*/
   // this.roomFilterService.initFilters();
   // this.eventsService.datosMatriculaEvent$.subscribe((object: any) => {
    //  this.roomFilterService.initFilters();
   // });
  //  const expire = new Par('prueba', 'nombrePrueba', true);
   this.roomFilterService.initFilters();
    // this.roomFilterService.stateFilters$.subscribe(res => {
   this.assetsExpired.splice(0);
   this.assetsExpired.push(...this.roomFilterService.expired);
    /*  this.assetsCategorias.splice(0);
      this.assetsCategorias.push(...this.roomFilterService.categorias);
      this.assetsGeneros.splice(0);
      this.assetsGeneros.push(...this.roomFilterService.generos);
      this.assetsTitulaciones.splice(0);
      this.assetsTitulaciones.push(...this.roomFilterService.titulaciones);
      this.assetsAsignaturas.splice(0);
      this.assetsAsignaturas.push(...this.roomFilterService.asignaturas);
*/
    // this.loadControls();
    // });
   /* this.placeHolder = 'Criterio de búsqueda';
    this.querySearch = '';

    // Ordenar de mayor a menor los cursos academicos
    this.listadoCuraca.sort((a, b) => b - a);
    this.obtenerListadoViasTitulacion();*/
   if (!this.assetFechaConsulta) {
      this.assetFechaConsulta = this.roomFilterService.fechaConsulta;
      // this.assetFechaIni = new Date(Date.now()).toString();

    }
   /* if (!this.assetFechaFin) {
      this.assetFechaFin = this.roomFilterService.fechaFin;
    }
    this.assetAutorP = this.roomFilterService.autorP;
    this.assetTitulacionP = this.roomFilterService.titulacionP;
    this.assetAsignaturaP = this.roomFilterService.asignaturaP;
    this.assetCategoriaP = this.roomFilterService.categoriaP;
    this.assetCuraca = '';*/
   this.loadControls();
  }

  loadControls() {
    console.log('this assetsExpired', this.assetsExpired);
    const controlsExpired = this.assetsExpired.map(c => new UntypedFormControl(c.checked));
 //   const controlsCategorias = this.assetsCategorias.map(c => new FormControl(c.checked));
 //   const controlsGeneros = this.assetsGeneros.map(c => new FormControl(c.checked));
    const controlFechaConsulta = this.assetFechaConsulta;
   /* const controlFechaFin = this.assetFechaFin;
    const controlsAsignaturas = this.assetsAsignaturas.map(c => new FormControl(c.checked));
    const controlsTitulaciones = this.assetsTitulaciones.map(c => new FormControl(c.checked));
    const controlAutorP = this.assetAutorP;
    const controlAsignaturaP = this.assetAsignaturaP;
    const controlTitulacionP = this.assetTitulacionP;
    const controlCategoriaP = this.assetCategoriaP;
    const controlCuraca = this.assetCuraca;
    const controlViaTitulacion = this.assetViaTitulacion;
    const controlCodTitulacion = this.assetCodTitulacion;
    const controlsOtrasAsignaturas = this.assetsOtrasAsignaturas;
*/
    this.filterForm = this.formBuilder.group({
      assetsExpired: new UntypedFormArray(controlsExpired),
   //   assetsCategorias: new FormArray(controlsCategorias),
   //   assetsGeneros: new FormArray(controlsGeneros),
      assetFechaConsulta: new UntypedFormControl(controlFechaConsulta),
     /* assetFechaFin: new FormControl(controlFechaFin),
      assetsAsignaturas: new FormArray(controlsAsignaturas),
      assetsTitulaciones: new FormArray(controlsTitulaciones),
      assetAutorP: new FormControl(controlAutorP),
      assetTitulacionP: new FormControl(controlTitulacionP),
      assetAsignaturaP: new FormControl(controlAsignaturaP),
      assetCategoriaP: new FormControl(controlCategoriaP),
      assetCuraca: new FormControl(controlCuraca),
      assetViaTitulacion: new FormControl(controlViaTitulacion),
      assetCodTitulacion: new FormControl(controlCodTitulacion),
      assetsOtrasAsignaturas: new FormControl(controlsOtrasAsignaturas),*/
    });
  }
/*  obtenerListadoViasTitulacion() {
    this.loadingListadoViasTitulacion = true;
    this.loadingListadoTitulaciones = true;
    this.loadingListadoAsignaturasTitulacionAnyo = true;
    this.viaTitulacion = null;
    this.codTitulacion = null;
    this.asignaturas = [];
    this.srapi.obtenerListadoViasTitulacion().subscribe((res: any) => {
      this.listadoViasTitulacion = res.data.map((vt) => vt.VIA_TITULACION);
      this.listadoViasTitulacion = this.listadoViasTitulacion.sort((a, b) => {
        const aNameNormalized = a
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
        const bNameNormalized = b
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
        if (aNameNormalized > bNameNormalized) {
          return 1;
        } else if (aNameNormalized < bNameNormalized) {
          return -1;
        } else {
          return 0;
        }
      });
      //  console.log('Vias titulacion: ', this.listadoViasTitulacion);
      this.loadingListadoViasTitulacion = false;
      this.changeDetectorRef.detectChanges();
    });
  }
  obtenerListadoTitulaciones() {
    this.loadingListadoTitulaciones = true;
    this.loadingListadoAsignaturasTitulacionAnyo = true;
    this.codTitulacion = null;
    this.asignaturas = [];
    this.srapi
        .obtenerListadoTitulaciones(this.viaTitulacion)
        .subscribe((res: any) => {
          // console.log(res);
          const titulaciones = res.data;
          this.listadoTitulaciones = [];
          titulaciones.forEach((t) => {
            const titulacion: Titulacion = new Titulacion(t);
            // Se eliminan las palabras redundantes de la titulacion
            titulacion.TITULACION = titulacion.TITULACION.replace(
                new RegExp(
                    '(ACCESO )|(GRADO EN )|(DOCTORADO EN )|(PROGRAMA DE DOCTORADO EN )'
                ),
                ''
            );
            this.listadoTitulaciones.push(titulacion);
          });
          this.listadoTitulaciones = this.listadoTitulaciones.sort((a, b) => {
            const aNameNormalized = a.TITULACION.normalize('NFD').replace(
                /[\u0300-\u036f]/g,
                ''
            );
            const bNameNormalized = b.TITULACION.normalize('NFD').replace(
                /[\u0300-\u036f]/g,
                ''
            );
            if (aNameNormalized > bNameNormalized) {
              return 1;
            } else if (aNameNormalized < bNameNormalized) {
              return -1;
            } else {
              return 0;
            }
          });
          //   console.log('Titulaciones: ', this.listadoTitulaciones);
          this.loadingListadoTitulaciones = false;
          this.changeDetectorRef.detectChanges();
        });
  }
  obtenerListadoAsignaturasTitulacionAnyo() {
    this.loadingListadoAsignaturasTitulacionAnyo = true;
    this.srapi
        .obtenerListadoAsignaturasTitulacionAnyo(this.codTitulacion, this.curaca)
        .subscribe((res: any) => {
          // console.log(res);
          this.asignaturas = [];
          const asignaturas = res.data;
          this.listadoAsignaturasTitulacionAnyo = [];
          asignaturas.forEach((a) => {
            this.listadoAsignaturasTitulacionAnyo.push(new Asignatura(a));
          });
          this.listadoAsignaturasTitulacionAnyo = this.listadoAsignaturasTitulacionAnyo.sort(
              (a, b) => {
                const aNameNormalized = a.ASIGNATURA.normalize('NFD').replace(
                    /[\u0300-\u036f]/g,
                    ''
                );
                const bNameNormalized = b.ASIGNATURA.normalize('NFD').replace(
                    /[\u0300-\u036f]/g,
                    ''
                );
                if (aNameNormalized > bNameNormalized) {
                  return 1;
                } else if (aNameNormalized < bNameNormalized) {
                  return -1;
                } else {
                  return 0;
                }
              }
          );
          //    console.log('Asignaturas: ', this.listadoAsignaturasTitulacionAnyo);
          this.loadingListadoAsignaturasTitulacionAnyo = false;
          this.changeDetectorRef.detectChanges();
        });
  }

  curacaChanged() {

    this.selectedAssetCuraca = this.filterForm.get('assetCuraca').value;
    this.curaca = this.selectedAssetCuraca;

    this.loadingListadoAsignaturasTitulacionAnyo = true;
    this.loadingListadoTitulaciones = true;
    this.loadingListadoViasTitulacion = false;
    this.loadingCuraca = false;
    this.viaTitulacion = null;
    this.codTitulacion = null;
    this.asignaturas = [];
    this.selectedTextAsignatura = '';
    this.filterForm.get('assetViaTitulacion').reset();
    this.filterForm.get('assetCodTitulacion').reset();
    this.filterForm.get('assetsOtrasAsignaturas').reset();
    this.changeDetectorRef.detectChanges();
  }
  viaTitulacionChanged() {
    this.selectedAssetViaTitulacion = this.filterForm.get('assetViaTitulacion').value;
    this.viaTitulacion = this.selectedAssetViaTitulacion;
    // console.log('Via titulación changed', this.viaTitulacion);
    if (this.viaTitulacion) {
      this.obtenerListadoTitulaciones();
    }
  }
  titulacionChanged() {
    this.selectedAssetCodTitulacion = this.filterForm.get('assetCodTitulacion').value;
    this.codTitulacion = this.selectedAssetCodTitulacion;

    console.log('Titulación changed', this.codTitulacion);
    if (this.codTitulacion) {
      this.nombreTitulacion = this.listadoTitulaciones.find(
          (t) => t.COD_TITULACION === this.codTitulacion
      ).TITULACION;
      //   console.log('Nombre de la nueva titulación: ', this.nombreTitulacion);
      this.obtenerListadoAsignaturasTitulacionAnyo();
    }
  }
  asignaturasChanged() {
    this.selectedAssetsOtrasAsignaturas = this.filterForm.get('assetsOtrasAsignaturas').value;
    this.asignaturas = this.selectedAssetsOtrasAsignaturas;
    if (this.asignaturas && this.asignaturas.length > 0) {
      this.selectedTextAsignatura = this.asignaturas.length + ' asignaturas seleccionadas';
    }
  }*/
  public applyFilters() {

    this.roomFilterService.changeFilters = false;
    this.roomFilterService.optionFilters = 'ninguno';


    // this.roomFilterService.setBtnOption('btnNextORBack', 'Next');

    // this.roomFilterService.setOptionProcessDetails('dataProcessDetail', this.dataProcessDetail);
    // this.roomFilterService.expired.forEach((c, index) => c.checked = this.dataProcessDetail.selectedAssetsExpired.assetsGeneros[index]);
    // this.roomFilterService.generos.forEach((c, index) => c.checked = this.dataProcessDetail.selectedAssetsGeneros.assetsGeneros[index]);
    // this.roomFilterService.categorias.forEach((c, index) =>
    //     c.checked = this.dataProcessDetail.selectedAssetsCategorias.assetsCategorias[index]);
    // this.roomFilterService.repositorios.forEach((c, index) =>
    //     c.checked = this.dataProcessDetail.selectedAssetsRepositorios.assetsRepositorios[index]);

   /*
    if (this.dataProcessDetail.selectedAssetTitulacionP.checked) {
      this.roomFilterService.addTitulacionPlayer(this.roomFilterService.titulacionP.Codigo, this.roomFilterService.titulacionP.Nombre);
      this.roomFilterService.optionFilters = 'alguno';
    } else {
      this.roomFilterService.resetTitulacionPlayer();
    }

    if (this.dataProcessDetail.selectedAssetAsignaturaP.checked) {
      this.roomFilterService.addAsignaturaPlayer(this.roomFilterService.asignaturaP.Codigo, this.roomFilterService.asignaturaP.Nombre);
      this.roomFilterService.optionFilters = 'alguno';
    } else {
      this.roomFilterService.resetAsignaturaPlayer();
    }

    if (this.dataProcessDetail.selectedAssetAutorP.checked) {
      this.roomFilterService.addAutorPlayer(this.roomFilterService.autorP.Codigo, this.roomFilterService.autorP.Nombre);
      this.roomFilterService.optionFilters = 'alguno';
    } else {
      this.roomFilterService.resetAutores();
      this.roomFilterService.resetAutorPlayer();
    }

    if (this.dataProcessDetail.selectedAssetCategoriaP.checked) {
      this.roomFilterService.addCategoriaPlayer(this.roomFilterService.categoriaP.Codigo, this.roomFilterService.categoriaP.Nombre);
      this.roomFilterService.optionFilters = 'alguno';
    } else {
      this.roomFilterService.resetCategoriaPlayer();
    }

    this.roomFilterService.TITULACIONES_FILTERS = [];

    this.roomFilterService.titulaciones.forEach((c, index) => {
      c.checked = this.dataProcessDetail.selectedAssetsTitulaciones[index];
      if (this.dataProcessDetail.selectedAssetsTitulaciones[index]) {
        this.roomFilterService.addTitulacionFilter(this.roomFilterService.titulaciones[index].Codigo);
        this.roomFilterService.optionFilters = 'alguno';
      }
    });
    // se agregan la "otra" titulacion seleccionada
    if (this.codTitulacion && this.nombreTitulacion) {
      if (this.roomFilterService.titulaciones.findIndex(item => item.Codigo === this.codTitulacion) === -1) {
        this.roomFilterService.addTitulacionFilter(this.codTitulacion);
      }
    }

    this.roomFilterService.ASIGNATURAS_FILTERS = [];

    this.roomFilterService.asignaturas.forEach((c, index) => {
      c.checked = this.dataProcessDetail.selectedAssetsAsignaturas[index];
      if (this.dataProcessDetail.selectedAssetsAsignaturas[index]) {
        this.roomFilterService.addAsignaturaFilter(this.roomFilterService.asignaturas[index].Codigo);
        this.roomFilterService.optionFilters = 'alguno';
      }
    });
    // se agregan las "otras" asignaturas seleccionadas
    if (this.asignaturas) {
      this.asignaturas.forEach((a) => {
        if (this.roomFilterService.asignaturas.findIndex(item => item.Codigo === a.COD_ASIGNATURA) === -1) {
          this.roomFilterService.addAsignaturaFilter(a.COD_ASIGNATURA);
        }
      });
    }

    */
    // añadido para reactive forms
  //  this.roomFilterService.REPOSITORIOS_FILTERS = [];

   /* this.roomFilterService.repositorios.forEach((c, index) => {
      c.checked = this.dataProcessDetail.selectedAssetsRepositorios[index];
      if (this.dataProcessDetail.selectedAssetsRepositorios[index]) {
        this.roomFilterService.addRepositorioFilter(this.roomFilterService.repositorios[index].Codigo);
        this.roomFilterService.optionFilters = 'alguno';
      }
    });*/

    /*this.roomFilterService.CATEGORIAS_FILTERS = [];

    this.roomFilterService.categorias.forEach((c, index) => {
      c.checked = this.dataProcessDetail.selectedAssetsCategorias[index];
      if (this.dataProcessDetail.selectedAssetsCategorias[index]) {
        this.roomFilterService.addCategoriaFilter(this.roomFilterService.categorias[index].Codigo);
        this.roomFilterService.optionFilters = 'alguno';
      }
    });

    this.roomFilterService.GENEROS_FILTERS = [];
    this.roomFilterService.generos.forEach((c, index) => {
      c.checked = this.dataProcessDetail.selectedAssetsGeneros[index];
      if (this.dataProcessDetail.selectedAssetsGeneros[index]) {
        this.roomFilterService.addGeneroFilter(this.roomFilterService.generos[index].Codigo);
        this.roomFilterService.optionFilters = 'alguno';
      }
    });
    */

   //  this.roomFilterService.EXPIRED_FILTERS = [];

    this.roomFilterService.expired.forEach((c, index) => {
      c.checked = this.dataProcessDetail.selectedAssetsExpired[index];
      if (this.dataProcessDetail.selectedAssetsExpired[index]) {
        this.roomFilterService.addExpiredFilter();
        this.roomFilterService.optionFilters = 'alguno';
      }
    });
    /*
    // console.log('generos en home service', this.homeService.generos);
    this.roomFilterService.fechaIni = this.dataProcessDetail.selectedAssetFechaIni;
    this.roomFilterService.fechaFin = this.dataProcessDetail.selectedAssetFechaFin;
  */
    this.roomFilterService.fechaConsulta = this.dataProcessDetail.selectedAssetFechaConsulta;

    if (!this.roomFilterService.changeFilters) {
      // this.homeService.fechaIni = this.dataProcessDetail.assetFechaIni;
      // this.homeService.fechaFin = this.dataProcessDetail.assetFechaFin;
      this.roomFilterService.changeFilters = !this.checkFechasEqual();
      // this.roomFilterService.oldFechaIni = this.roomFilterService.fechaIni;
      // this.roomFilterService.oldFechaFin = this.roomFilterService.fechaFin;
      this.roomFilterService.oldFechaConsulta = this.roomFilterService.fechaConsulta;
      this.roomFilterService.noActivedFilters = false;
    }
    //   this._home.noActivedFilters=true;
    this.roomFilterService.updateMessage();
  }
  public checkFechasEqual(): boolean {
    if (
        /*this.roomFilterService.fechaIni !== this.dataProcessDetail.selectedAssetFechaIni ||
        this.roomFilterService.fechaFin !== this.dataProcessDetail.selectedAssetFechaFin */
        this.roomFilterService.fechaConsulta !== this.dataProcessDetail.selectedAssetFechaConsulta
    ) {
      return false;
    } else {
      return true;
    }
  }

  desplegar(seccion: string) {
    //  console.log('Desplegar clicked');
    this.desplegables.map((d) => {
      if (d.seccion === seccion) {
        d.desplegado = !d.desplegado;
      } else {
        d.desplegado = false;
      }
    });
    this.changeDetectorRef.detectChanges();
    //  console.log('Estado de desplegables: ', this.desplegables);

  }
  async presentOrdenarActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Ordenar por',
      buttons: this.generateOrdenarButtons(),
      cssClass: 'order-custom-action-sheet'
    });

    await actionSheet.present();
    await actionSheet.onDidDismiss();
  }
  getOrderFilter() {
    const orderFilter = this.roomFilterService.getSortParam();
    switch (orderFilter) {
      case '': return 'Recientes';
      case 'Fecha_dt': return 'Recientes';
      case 'Antiguo': return 'Antiguos';
      // case 'Relevancia': return 'Relevancia';
      // case 'Num_Visitas_i': return 'Más visitados';
    }
  }
  cancelarBusqueda() {
    this.dataProcessDetail = this.roomFilterService.getOptionProcessDetails();
    // console.log('ReloadProcessDetails cancelarBusqueda->', this.dataProcessDetail);
    this.filterForm.patchValue({assetsExpired: this.dataProcessDetail.selectedAssetsExpired});
    /*this.filterForm.patchValue({assetsCategorias: this.dataProcessDetail.selectedAssetsCategorias});
    this.filterForm.patchValue({assetsGeneros: this.dataProcessDetail.selectedAssetsGeneros});
    */
    this.filterForm.patchValue({assetFechaConsulta: this.dataProcessDetail.selectedAssetFechaConsulta});
    /*this.filterForm.patchValue({assetFechaFin: this.dataProcessDetail.selectedAssetFechaFin});
    this.filterForm.patchValue({assetsTitulaciones: this.dataProcessDetail.selectedAssetsTitulaciones});
    this.filterForm.patchValue({assetsAsignaturas: this.dataProcessDetail.selectedAssetsAsignaturas});
    this.filterForm.patchValue({assetAutorP: this.dataProcessDetail.selectedAssetAutorP});
    this.filterForm.patchValue({assetTitulacionP: this.dataProcessDetail.selectedAssetTitulacionP});
    this.filterForm.patchValue({assetAsignaturaP: this.dataProcessDetail.selectedAssetAsignaturaP});
    this.filterForm.patchValue({assetCategoriaP: this.dataProcessDetail.selectedAssetCategoriaP});*/
    // this.filterForm.patchValue({assetCuraca: this.dataProcessDetail.selectedAssetCuraca});
    // this.filterForm.patchValue({assetViaTitulacion: this.dataProcessDetail.selectedAssetViaTitulacion});
    // this.filterForm.patchValue({assetCodTitulacion: this.dataProcessDetail.selectedAssetCodTitulacion});
    // this.filterForm.patchValue({assetOtrasAsignaturas: this.dataProcessDetail.selectedAssetOtrasAsignaturas});
    this.dismiss();
  }
  borrarFiltros(value: boolean) {
    console.log('Borrar filtros');
    const controlsExpired = this.assetsExpired.map(c => new UntypedFormControl(value));
    /*const controlsCategorias = this.assetsCategorias.map(c => new FormControl(value));
    const controlsGeneros = this.assetsGeneros.map(c => new FormControl(value));
    */
    this.roomFilterService.initFechas();
    const controlFechaConsulta = this.roomFilterService.fechaConsulta;
    /*const controlFechaFin = this.roomFilterService.fechaFin;
    const controlsTitulaciones = this.assetsTitulaciones.map(c => new FormControl(value));
    const controlsAsignaturas = this.assetsAsignaturas.map(c => new FormControl(value));
    this.assetAutorP.checked = value;
    const controlAutorP = this.assetAutorP.checked;
    this.assetTitulacionP.checked = value;
    const controlTitulacionP = this.assetTitulacionP.checked;
    this.assetAsignaturaP.checked = value;
    const controlAsignaturaP = this.assetAsignaturaP.checked;
    this.assetCategoriaP.checked = value;
    const controlCategoriaP = this.assetCategoriaP.checked;
    this.assetCuraca = '';
    const controlCuraca = this.assetCuraca;
    this.assetViaTitulacion = '';
    const controlViaTitulacion = this.assetViaTitulacion;
    this.assetCodTitulacion = '';
    const controlCodTitulacion = this.assetCodTitulacion;
    const controlsOtrasAsignaturas = this.assetsOtrasAsignaturas.map(c => new FormControl(value));

*/
    this.filterForm = this.formBuilder.group({
      assetsExpired: new UntypedFormArray(controlsExpired),
      // assetsCategorias: new FormArray(controlsCategorias),
      // assetsGeneros: new FormArray(controlsGeneros),
      assetFechaConsulta: new UntypedFormControl(controlFechaConsulta),
      /*assetFechaFin: new FormControl(controlFechaFin),
      assetsTitulaciones: new FormArray(controlsTitulaciones),
      assetsAsignaturas: new FormArray(controlsAsignaturas),
      assetAutorP: new FormControl(controlAutorP),
      assetTitulacionP: new FormControl(controlTitulacionP),
      assetAsignaturaP: new FormControl(controlAsignaturaP),
      assetCategoriaP: new FormControl(controlCategoriaP),
      assetCuraca: new FormControl(controlCuraca),
      assetViaTitulacion: new FormControl(controlViaTitulacion),
      assetCodTitulacion: new FormControl(controlCodTitulacion),
      assetsOtrasAsignaturas: new FormControl(controlsOtrasAsignaturas)*/
    });
    console.log('VALORES DEL FORM en el BORRAR FILTROS-> ', this.filterForm.value);
    this.roomFilterService.setCheckedAll(false);
    this.changeDetectorRef.detectChanges();
    this.presentToast('Todos los filtros eliminados');
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: new IonicSafeString(message),
      duration: 3000
    });
    toast.setAttribute('role', 'alert');
    toast.setAttribute('aria-live', 'assertive');
    toast.present();
  }
  submitForm() {
    //   console.log('SUBMIT Buscando grabaciones ...');
 //   this.roomFilterService.queryParam = this.querySearch;
    this.selectedAssetsExpired = this.filterForm.get('assetsExpired').value;
   // this.selectedAssetsCategorias = this.filterForm.get('assetsCategorias').value;
   // this.selectedAssetsGeneros = this.filterForm.get('assetsGeneros').value;
    this.selectedAssetFechaConsulta = this.filterForm.get('assetFechaConsulta').value;
   /* this.selectedAssetFechaFin = this.filterForm.get('assetFechaFin').value;
    this.selectedAssetsTitulaciones = this.filterForm.get('assetsTitulaciones').value;
    this.selectedAssetsAsignaturas = this.filterForm.get('assetsAsignaturas').value;
    this.selectedAssetAutorP = this.filterForm.get('assetAutorP').value;
    this.selectedAssetTitulacionP = this.filterForm.get('assetTitulacionP').value;
    this.selectedAssetAsignaturaP = this.filterForm.get('assetAsignaturaP').value;
    this.selectedAssetCategoriaP = this.filterForm.get('assetCategoriaP').value;
    this.selectedAssetCuraca = this.filterForm.get('assetCuraca').value;
    this.selectedAssetViaTitulacion = this.filterForm.get('assetViaTitulacion').value;
    this.selectedAssetCodTitulacion = this.filterForm.get('assetCodTitulacion').value;
    this.selectedAssetsOtrasAsignaturas = this.filterForm.get('assetsOtrasAsignaturas').value;
*/
    console.log('VALORES DEL FORM en el SUBMIT-> ', this.filterForm.value);

    this.dataProcessDetail = {
      selectedAssetsExpired: this.selectedAssetsExpired,
   //   selectedAssetsCategorias: this.selectedAssetsCategorias,
    //  selectedAssetsGeneros: this.selectedAssetsGeneros,
      selectedAssetFechaConsulta: this.selectedAssetFechaConsulta,
     /* selectedAssetFechaFin: this.selectedAssetFechaFin,
      selectedAssetsTitulaciones: this.selectedAssetsTitulaciones,
      selectedAssetsAsignaturas: this.selectedAssetsAsignaturas,
      selectedAssetAutorP: this.selectedAssetAutorP,
      selectedAssetTitulacionP: this.selectedAssetTitulacionP,
      selectedAssetAsignaturaP: this.selectedAssetAsignaturaP,
      selectedAssetCategoriaP: this.selectedAssetCategoriaP,
      selectedAssetCuraca: this.selectedAssetCuraca,
      selectedViaTitulacion: this.selectedAssetViaTitulacion,
      selectedCodTitulacion: this.selectedAssetCodTitulacion,
      selectedOtrasAsignaturas: this.selectedAssetsOtrasAsignaturas*/
    };
    // console.log('dataprocessdetail en SUBMIT', this.dataProcessDetail);
    this.applyFilters();
    // console.log('como quedan en el servicio', this.homeService.generos,this.homeService.categorias);
    this.dismiss();
  }
  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  private generateOrdenarButtons(): any {
    const actionSheetButtons = [];
    const orderFilter = this.roomFilterService.getSortParam();
    console.log('OrderFilter applied: ', orderFilter);
    // console.log('Is the order filter empty? ', (orderFilter === '' || orderFilter === 'Fecha_dt'));
    actionSheetButtons.push({
      text: 'Recientes',
      icon: 'calendar',
      role: (orderFilter === 'Fecha_dt') ? 'selected' : 'order',
      cssClass: (orderFilter === 'Fecha_dt') ? 'selected-icon' : '',
      handler: () => {
        console.log('Ordenar por Recientes clicked');
        this.loadingRooms = true;
        this.roomFilterService.setSortParam('Fecha_dt');
        // this.roomService.getListadoSalasSolr(this.roomService.querySearch, this.roomService.filters,
        //    'Fecha_dt', this.roomService.start, 20);
        // this.roomService.getListadoSalasSolr(0, 15);
      },
    });
    actionSheetButtons.push({
      text: 'Antiguos',
      icon: 'calendar',
      role: orderFilter === 'Antiguo' ? 'selected' : 'order',
      cssClass: orderFilter === 'Antiguo' ? 'selected-icon' : '',
      handler: () => {
        console.log('Ordenar por Antiguos clicked');
        this.loadingRooms = true;
        this.roomFilterService.setSortParam('Antiguo');
        // this.roomService.getListadoSalasSolr(this.roomService.querySearch, this.roomService.filters,
        //    'Antiguo', this.roomService.start, 20);
        // this.roomService.getListadoSalasSolr( 0, 15);
      },
    });
    /* actionSheetButtons.push({
       text: 'Relevancia',
       icon: 'reorder-four',
       role: (orderFilter === '' || orderFilter === 'Relevancia') ? 'selected' : 'order',
       cssClass: (orderFilter === '' || orderFilter === 'Relevancia') ? 'selected-icon' : '',
       handler: () => {
         console.log('Ordenar por Relevancia clicked');
         this.loadingRooms = true;
         this.roomFilterService.setSortParam('Relevancia');
         // this.roomService.getListadoSalasSolr( 0, 15);
       },
     });
     actionSheetButtons.push({
       text: 'Más visitados',
       icon: 'eye',
       role: orderFilter === 'Num_Visitas_i' ? 'selected' : 'order',
       cssClass: orderFilter === 'Num_Visitas_i' ? 'selected-icon' : '',
       handler: () => {
         console.log('Ordenar por Más visitados clicked');
         this.loadingRooms = true;
         this.roomFilterService.setSortParam('Num_Visitas_i');
         // this.roomService.getListadoSalasSolr( 0, 15);
       },
     });*/
    actionSheetButtons.push({
      text: 'Cancelar',
      icon: 'close',
      role: 'cancel',
      handler: () => {
        console.log('Cancelar clicked');
      },
    });
    return actionSheetButtons;
  }

}
